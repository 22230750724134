// Capacitor 2
import { Plugins } from '@capacitor/core'
const { Device, Storage } = Plugins

// Capacitor 3
// import { Storage } from '@capacitor/storage'
// import { Device } from '@capacitor/device'

import i18n from './index'
import moment from 'moment'
import {Locale, Path, PostTranslationHandler, VueMessageType} from "vue-i18n"
import { ComponentInternalInstance } from 'vue'
// @ts-expect-error i dont know how ts works
import {MessageType, Message} from "@intlify/runtime"

class Translations {
	n = '🔴'
	y = '🟢'
	locale =  ''
	defaultLocale =  ''
	settings =  {}
	constructor() {
		this._loadOrGetLocale()
		moment.locale(this.defaultLocale)
	}
	setup = (defaultLocale: Locale, settings: {[key: string]: string}) => {
		this.defaultLocale = defaultLocale
		this.settings = settings
	}
	setLocale = (locale: Locale) => {
		this.locale = locale
		this._switchTranslationSign()
		if (navigator.cookieEnabled) {
			i18n.global.locale = locale
		}
		// 	Storage.set({ key: 'appfumbi_language', value: JSON.stringify({value: locale}) })
	}
	getLocale = () => {
		return this.locale
	}
	getLocales = () => {
		return this.settings
	}
	getDefaultLocale = () => {
		return this.defaultLocale
	}
	missingTranslation = (locale: Locale, key: Path, instance?: ComponentInternalInstance, type?: string) => {
		// if (_env('TRANSLATION_DEBUG')) {
		//   return key + this.n
		// } else
		return key
	}
	postTranslation = (translated: MessageType<Message>): PostTranslationHandler<VueMessageType> => {
		// if (_env('TRANSLATION_DEBUG')) {
		//   if (string === '') return key + this.n
		//   return string.includes(this.n) ? string : string + this.y
		// } else
		// @ts-expect-error
		return translated
	}
	loadLocaleMessages() {
		const locales = require.context(
			'@/locales',
			true,
			/[A-Za-z0-9-_,\s]+\.json$/i
		)
		const messages: {[key: string]: {[key: string]: string}} = {}

		locales.keys().forEach((key) => {
			const matched = key.match(/([A-Za-z0-9-_]+)\./i)
			if (matched && matched.length > 1) {
				const locale = matched[1]

				messages[locale] = locales(key)
			}
		})
		return messages
	}
	_switchTranslationSign() {
		this.n = this.locale === this.defaultLocale ? '🟢' : '🔴'
	}
	async _loadOrGetLocale() {
		// let locale

		if (globalThis.Cookiebot.consented) {
			let storageLocale = await Storage.get({ key: 'appfumbi_language' })

			if (storageLocale) storageLocale = JSON.parse(storageLocale.value)

			if(storageLocale?.value) this.locale = storageLocale.value
			else this.locale = this.defaultLocale
		} else {
			this.locale = this.defaultLocale
		}

		this.setLocale(this.locale)
	}
}
export default new Translations()
