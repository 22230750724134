<template>
	<section>
		<ion-row class="row container">

			<ion-col size="12" size-lg="6" data-aos="fade-up" data-aos-delay="200">
				<div class="fumbi-individual banner text-center">
					<div class="width-100">
						<h5 class="mb-3">
							Fumbi <strong>Individual</strong>
						</h5>
						<h2 class="fw-bold">
							{{$t('fumbi-individual-banner-title')}}
						</h2>
					</div>
					<p class="text-content pb-1">
						{{$t('fumbi-individual-banner-text')}}
					</p>
					<ion-button @click="openExternalLink(`https://fumbi.network/${$w18n.currentLanguage.value.value}`)">{{$t('I am interested')}}</ion-button>
				</div>
			</ion-col>

			<ion-col size="12" size-lg="6" data-aos="fade-up" data-aos-delay="200">
				<div class="fumbi-biznis banner text-center">
					<div class="width-100">
						<h5 class="mb-3">
							Fumbi <strong>{{$t('Biznis')}}</strong>
						</h5>
						<h2 class="fw-bold">
							{{$t('fumbi-biznis-banner-title')}}
						</h2>
					</div>
					<p class="text-content pb-1">
						{{$t(`fumbi-biznis-banner-text`)}}
					</p>
					<ion-button color="primary" @click="openExternalLink(`https://fumbi.network/${$w18n.currentLanguage.value.value}/biznis`)">{{$t('I am interested')}}</ion-button>
				</div>
			</ion-col>

		</ion-row>
	</section>
</template>

<script>

export default {
	methods: {
		openExternalLink(link) {
			window.open(link)
		},
	}
}
</script>


<style lang="sass" scoped>
ion-col 
	--ion-grid-column-padding: 10px

.container
	margin-top: 100px

.banner
	height: 100%
	padding: 60px 25px
	border-radius: 9px
	display: flex
	flex-direction: column
	justify-content: space-between
	color: white

	h5
		font-weight: 400
		font-size: 15px
		margin-top: 0

	p
		color: inherit
		margin: 20px 0

	ion-button
		width: 200px
		margin: 0 auto

.fumbi-biznis
	background: #2e2f31

	h5
		// color: #008CE7
		color: #9dc41a

.fumbi-individual
	background:  #9dc41a

	h5
		color: #2e2f31

	ion-button
		--background: #2e2f31
		--background-hover: #434446
		--background-focused: #434446
		--background-activated: #28292b



	// --ion-color-primary: #2e2f31;
	// --ion-color-primary-rgb: 46,47,49;
	// --ion-color-primary-contrast: #ffffff;
	// --ion-color-primary-contrast-rgb: 255,255,255;
	// --ion-color-primary-shade: #28292b;
	// --ion-color-primary-tint: #434446;
</style>