<template>
	<ion-page>
		<A-menu content-id="main" />
		<A-header />

		<ion-content class="ion-no-padding">
			<ion-router-outlet id="main" :key="$route.name" />
		</ion-content>
	</ion-page>
</template>

<script>
import AMenu from "./header/_components/a-menu.vue"
import AHeader from "@/plugins/appfumbi/_layout/header/a-header.vue"

export default {
	components: {
		AMenu,
		AHeader
	}
}
</script>
