import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'
import wRoute from '@/plugins/w/route'
import { i18n } from './main'

const routes: Array<RouteRecordRaw> = [
	// {
	// 	path: '/',
	// 	redirect: () => {
	// 		return 'sk'
	// 	}
	// },
	{
		path: '/',
		// path: '/:locale',
		component: () => import('./_layout/default.layout.vue'),
		children: [
			wRoute('home',						'',													() => import('@/plugins/appfumbi/views/home/home.vue')),
			wRoute('lessons',					'lessons',											() => import('@/plugins/lib@lesson/lessons/lessons.vue')),
			wRoute('lesson',					'lessons/:lessonSlug',								() => import('@/plugins/lib@lesson/lesson/lesson.vue')),
			
			wRoute('profile',       			'profile/:profileTab?',       						() => import('@/plugins/lib@profile/profile/profile.vue'),  true ),

			wRoute('login-required',       		'login-required/:redirectToUrl',       			() => import('@/plugins/appfumbi/views/auth/login-required.vue')),
			wRoute('login-successful',       	'login-successful/:redirectToUrl',       			() => import('@/plugins/appfumbi/views/auth/login-successful.vue')),

			wRoute('404',       				':pageName',       									() => import('@/plugins/appfumbi/views/404/404.vue'))			
		]
	}
]

const router = createRouter({
	history: createWebHistory(process.env.VUE_APP_BASE_URL),
	routes
})


function setLanguage(to) {
	const urlLang	= to.query?.lang
	let lang		= i18n.availableLanguages.find(language => language.value == urlLang)

	if (!lang) return
	i18n.changeLanguage(lang)
}

router.beforeEach((to) => {
	setLanguage(to)
	window.postMessage(to.fullPath, '*')
})

export default router
