<template>
	<div class="content" data-aos="fade-up">
		<ion-row>
			<ion-col size="12" class="text-center mb-5">
				<!-- <span class="headline">{{$t('We recommend')}}</span> -->
				<h2 class="fw-bold"><span class="color-primary">{{$t('Other lessons')}}</span> {{$t('with Fumbi')}}</h2>
			</ion-col>
		</ion-row>

		<div class="horizontal-scroll d-flex ion-nowrap">
			<div class="lesson-wrapper" v-for="lesson in lessons" :key="lesson.id">
				<Z-lesson-card :lesson="lesson" :grid="true" />
			</div>
		</div>
	</div>
</template>

<script>
import wAxios from '@/plugins/w/axios'
import ZLessonCard from '@/plugins/lib@lesson/_components/z-lesson-card.vue'

const api = {
	getlessons: () => wAxios.get_auth_data('v1/suggested-lessons')
}

export default {
	components: {
		ZLessonCard,
	},
	data() {
		return {
			lessons: []
		}
	},
	async created() {
		try {
			this.lessons = await api.getlessons()
		} catch (error) {
			this.lessons = {error: error}
		}
	},
	methods: {
	},
}
</script>

<style lang="sass" scoped>
.content
	padding-top: 100px
	width: 900px
	max-width: 100%
	margin: auto

.horizontal-scroll
	width: 100%
	overflow-x: scroll
	overflow-y: hidden
	white-space: nowrap
	-ms-overflow-style: none
	scrollbar-width: none

	&::-webkit-scrollbar
		display: none

	.lesson-wrapper
		width: 280px
		min-width: 280px
		margin-right: 20px
		overflow: hidden

@media (max-width: 1023px)
	.content 
		padding-top: 90px

@media (max-width: 915px)
	.horizontal-scroll
		padding-left: 15px

@media (max-width: 767px)
	.content 
		padding-top: 60px
</style>