<template>
	<ion-grid class="container"> 

		<ion-row>

			<ion-col size="12" class="text-center mb-5" data-aos="fade-up">
				<!-- <span class="headline">{{$t('Benefits for you')}}</span> -->
				<h2 class="fw-bold">{{$t('Benefits')}} <span>Fumbi</span></h2>
			</ion-col>

			<ion-col size="12" size-md="6" size-lg="4" class="advantage" data-aos="fade-up">
				<img class="icon" src="./img/check.svg" alt="check">
				<h4 class="mb-1">{{$t('Easy to invest')}}</h4>
				<p>{{$t('With just a few clicks, you can invest in a dynamic portfolio of top cryptocurrencies.')}}</p>
			</ion-col>

			<ion-col size="12" class="sm" data-aos="fade-up">
				<hr>
			</ion-col>

			<ion-col size="12" size-md="6" size-lg="4" class="advantage" data-aos="fade-up">
				<img class="icon" src="./img/graph.svg" alt="graph">
				<h4 class="mb-1">{{$t('Minimal risk')}}</h4>
				<p>{{$t('Your portfolio is specifically designed to track overall market value and not rely on individual cryptocurrencies.')}}</p>
			</ion-col>

			<ion-col size="12" class="md" data-aos="fade-up">
				<hr>
			</ion-col>

			<ion-col size="12" size-md="6" size-lg="4" class="advantage" data-aos="fade-up">
				<img class="icon" src="./img/award.svg" alt="award">
				<h4 class="mb-1">{{$t('Direct ownership')}}</h4>
				<p>{{$t('With us, you always own cryptocurrencies directly, not through collective investment.')}}</p>
			</ion-col>

			<ion-col size="12" class="lg" data-aos="fade-up">
				<hr>
			</ion-col>

			<ion-col size="12" size-md="6" size-lg="4" class="advantage" data-aos="fade-up">
				<img class="icon" src="./img/sgs.svg" alt="sgs">
				<h4 class="mb-1">{{$t('Independent audit')}}</h4>
				<p>{{$t('We are the first platform for investing in cryptocurrencies with an independent audit of wallets.')}}</p>
			</ion-col>

			<ion-col size="12" class="md" data-aos="fade-up">
				<hr>
			</ion-col>

			<ion-col size="12" size-md="6" size-lg="4" class="advantage" data-aos="fade-up">
				<img class="icon" src="./img/50.svg" alt="50">
				<h4 class="mb-1">{{$t('Small initial investment')}}</h4>
				<p>{{$t('You can invest through Fumbi from 50 €.')}}</p>
			</ion-col>

			<ion-col size="12" class="sm"  data-aos="ade-up">
				<hr>
			</ion-col>

			<ion-col size="12" size-md="6" size-lg="4" class="advantage" data-aos="fade-up">
				<img class="icon" src="./img/lock.svg" alt="lock">
				<h4 class="mb-1">{{$t('Maximum security')}}</h4>
				<p>{{$t('Ledger Vault is the most trusted and secure cryptocurrency storage solution in the world.')}}</p>
			</ion-col>

		</ion-row>

	</ion-grid>
</template>

<script>
export default {
	
}
</script>

<style lang="sass" scoped>
.container
	// padding-top: 200px
	padding-top: 150px
h2 
	color: var(--ion-color-primary)

	span
		color: var(--ion-color-dark)
.advantage
	padding: 16px 66px 36px 36px

	&:nth-child(2),
	&:nth-child(4),
	&:nth-child(8),
	&:nth-child(10)
		border-right: 1px solid #E6E6E6

	.icon
		margin-left: -26px
		margin-bottom: -26px
		width: 100px
ion-col.md,
ion-col.sm
	display: none
hr
	background-color: #E6E6E6
@media (max-width: 1023px)
	.container 
		// padding-top: 120px
		padding-top: 70px
	ion-col.lg
		display: none
	ion-col.md
		display: block
	.advantage
		&:nth-child(2),
		&:nth-child(6),
		&:nth-child(7),
		&:nth-child(9)
			border-right: 1px solid #E6E6E6
		&:nth-child(4),
		&:nth-child(8)
			border-right: none
@media (max-width: 767px)
	.container 
		// padding-top: 70px
		padding-top: 50px
	.advantage
		padding: 0
		&:nth-child(2),
		&:nth-child(5),
		&:nth-child(6),
		&:nth-child(9),
		&:nth-child(10)
			border-right: none
	ion-col.sm,
	ion-col.lg
			display: block
</style>