<template>
	<div class="container mt-5 pt-2">
		<ion-row>
			<ion-col size="12" class="text-center mb-2 mt-3" data-aos="fade-in">
				<!-- <span class="headline">{{$t('Education')}}</span> -->
				<h2 class="fw-bold">{{$t('Learn all about the')}} <span class="color-primary">{{$t('crypto')}}</span></h2>
			</ion-col>
		</ion-row>

		<div class="web width-100">
			<W-loadable :items="lessons" skelet="spinner">
				<ion-row class="mt-3">
					<ion-col v-for="lesson in lessons" :key="lesson.id" size="12" size-sm="6" size-lg="4" class="mb-2"  data-aos="fade-up">
						<Z-lesson-card :lesson="lesson" :grid="true" />
					</ion-col>
				</ion-row>
			</W-loadable>
		</div>

		<div class="mobile width-100">
			<div class="horizontal-scroll d-flex ion-nowrap">
				<div class="lesson-wrapper" v-for="lesson in lessons" :key="lesson.id">
					<Z-lesson-card :lesson="lesson" :grid="true"  />
				</div>
			</div>
		</div>

		<ion-button color="primary" @click="$router.push({name: 'lessons'})" data-aos="fade-up">{{$t('All themes')}}</ion-button>
	</div>
</template>

<script>
import ZLessonCard from '@/plugins/lib@lesson/_components/z-lesson-card.vue'

import wAxios from '@/plugins/w/axios'

const api = {
	getLessons: () => wAxios.get_auth_data(`v1/lessons?result_per_page=6`),
	userflag: (lessonId, type, value) =>  wAxios.post_auth_data(`/v1/userflag/lesson/${lessonId}`, {type: type, value: value})
}


export default {
	components: {
		ZLessonCard,
	},

	data() {
		return {
			lessons: null,
		}
	},

	async created() {
		this._loadData()
		this.eventBus.on('language-changed', this._loadData)
	},

	beforeUnmount() {
		this.eventBus.off('language-changed', this._loadData)
	},

	methods: {
		async _loadData() {
			if (this.$route.name != 'home') return

			try {
				this.lessons = null
				this.lessons = await api.getLessons()
				this.$aos.refresh()
			} catch(error) {
				this.lessons = error
			}
		}
	}
}
</script>

<style lang="sass" scoped>
.container
	max-width: 1000px
	padding: 0 10px

.horizontal-scroll
	width: 100%
	overflow-x: scroll
	overflow-y: hidden
	white-space: nowrap

	&::-webkit-scrollbar
		display: none

	.lesson-wrapper
		width: 285px
		min-width: 285px
		margin-right: 15px

		&:first-child
			margin-left: 15px

ion-button
	display: block
	width: 223px
	max-width: 100%
	margin: 60px auto 0

.web
	display: block

.mobile
	display: none

@media only screen and (max-width: 574px)
	.container
		padding: 0 !important
		margin-top: 0

	.web
		display: none

	.mobile
		display: block

</style>