<template>
	<ion-header>
		<ion-toolbar>

			<div class="lower-wrapper relative">
				<div class="container d-flex ion-align-items-center height-100">

					<img class="visible-sm menu" src="./img/menu.svg" alt="menu" @click="openMenu()">

					<img class="logo" src="./img/logo.svg" alt="logo" @click="$router.push({name: 'home'})">

					<img v-if="isLoggedIn" class="visible-sm" src="./img/profile.svg" alt="profile" @click="this.$router.push({name: 'profile'})">
					<div v-else />
				</div>
			</div>

		</ion-toolbar>
	</ion-header>
</template>

<script>
import { popoverController, menuController } from '@ionic/vue'
import { mapGetters} 			from 'vuex'
import APopover 				from '@/plugins/w/w18n/components/a-language-popover.vue'
import flag 					from '@/plugins/w/w18n/components/img/flags.png'

export default {
	data() {
		return {
			flag
		}
	},
	
	computed: {
		...mapGetters('wAuth', [
			'user',
			'isLoggedIn'
		])
	},

	methods: {
		openExternalLink(link) {
			window.open(link)
		},

		openMenu() {
			menuController.enable(true, 'first')
			menuController.open('first')
		},

		login() {
			const redirectParam = `${document.location.origin}/login-successful/${encodeURIComponent(this.$route.path)}`
			const fumbiLoginUrl	= `${process.env.VUE_APP_FUMBI_LOGIN_URL}/externalSignIn/index?redirectUrl=${encodeURIComponent(encodeURI(redirectParam))}&clientId=${process.env.VUE_APP_FUMBI_LOGIN_CLIENT_ID}`
			
			window.location.assign(fumbiLoginUrl)
		},

		async logout() {
			await this.$store.dispatch('wAuth/logout')
			window.open(location.origin, "_self")
		},

		async openPopover(ev) {
			const popover = await popoverController
				.create({
					component: APopover,
					componentProps: { 
						languages: this.$w18n.availableLanguages
					},
					event: ev,
					translucent: true
				})

			await popover.present()
		}
	},
}
</script>

<style lang="sass" scoped>

ion-header
	ion-toolbar 
		--border-width: 0px !important
		--padding-end: 0px
		--padding-start: 0px
		--padding-top: 0px
		--padding-bottom: 0px
		--min-height: 150px
		height: 150px


	.right-icon
		width: 19px

	.lower-wrapper
		height: 100px
		border-bottom: 1px solid rgba(0, 0, 0, .1)

		.menu
			height: 19px
			margin-top: 3px

		.logo
			height: 55px
			margin-right: 100px
			margin-top: -7px

		p
			position: relative
			padding: 0 15px
			margin: 0
			cursor: pointer
			&.is-active,
			&:hover
				color: var(--ion-color-primary)
		
		.dropdown
			display: block
			position: absolute
			top: 100%
			left: 15px
		
		.buttons
			margin: 0 0 0 auto

	.border-right
		border-right: 1px solid rgba(255, 255, 255, .2)

	@media (max-width: 992px)
		ion-toolbar
			--min-height: 64px
			height: 64px

		.lower-wrapper
			height: 64px
			.container
				justify-content: space-between

			ion-title:deep()
				.toolbar-title
					background: red !important

			.logo
				position: absolute
				top: 50%
				left: 50%
				transform: translate(-50%, -50%)
				margin: 0
				height: 35px

		.lower-wrapper .container
			justify-content: space-between

			ion-icon
				font-size: 40px

</style>