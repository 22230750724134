<template>
	<ion-menu side="start" menu-id="first" :content-id="contentId" type="push">
		<ion-header>
			<ion-toolbar>
			</ion-toolbar>
		</ion-header>

		<ion-content class="ion-no-padding">
			<ion-list class="menu" lines="none">
				<div class="background-wrapper">
					<ion-item class="header d-flex ion-align-items-center">
						<ion-label @click="$router.push({name: 'home'})">
							<img class="logo" src="./img/logo.svg" alt="logo">
						</ion-label>
						<img class="mr-1" src="./img/close.svg" alt="close" @click="closeMenu()">
					</ion-item>

					<hr class="header-underline">

					<!-- Lessons -->

					<ion-item @click="$router.push({name: 'lessons'})">
						<ion-label class="d-flex ion-align-items-center fw-bold" :class="{'is-active': $route.name === 'lessons'}"><img class="mr-1" src="./img/lessons.svg" alt="">{{$t('Lessons')}}</ion-label>
					</ion-item>
					<ion-item class="ml-5" @click="$router.push({name: 'lessons', params: {sortBy: 'all'}})">
						<ion-label>{{$t('All')}}</ion-label>
					</ion-item>
					<ion-item class="ml-5" @click="$router.push({name: 'lessons', params: {sortBy: 'date'}})">
						<ion-label>{{$t('Latest')}}</ion-label>
					</ion-item>
					<ion-item class="ml-5" @click="$router.push({name: 'lessons', params: {sortBy: 'like'}})">
						<ion-label>{{$t('Most popular')}}</ion-label>
					</ion-item>
					<hr>
				</div>

				<!-- Profile -->

				<div v-if="isLoggedIn">
					<ion-item @click="$router.push({name: 'profile'})">
						<ion-label class="d-flex ion-align-items-center fw-bold"><img class="mr-1" src="./img/profile.svg" alt="">Profil</ion-label>
					</ion-item>
					<ion-item class="ml-5" @click="$router.push({name: 'profile', params: {profileTab: 'user-form'}})" :class="{'is-active': $route.name === 'profile' && (!$route.params.profileTab || $route.params.profileTab == 'user-form')}">
						<ion-label>{{$t('Particulars')}}</ion-label>
					</ion-item>
					<ion-item class="ml-5" @click="$router.push({name: 'profile', params: {profileTab: 'user-themes'}})" :class="{'is-active': $route.name === 'profile' && $route.params.profileTab == 'user-themes'}">
						<ion-label>{{$t('My lessons')}}</ion-label>
					</ion-item>
					<ion-item class="ml-5" @click="$router.push({name: 'profile', params: {profileTab: 'user-liked'}})" :class="{'is-active': $route.name === 'profile' && $route.params.profileTab == 'user-liked'}">
						<ion-label>{{$t('Liked')}}</ion-label>
					</ion-item>

					<hr>
				</div>

				<!-- Support -->

				<ion-item @click="openExternalLink(`https://fumbi.network/${$w18n.currentLanguage.value.value}/pomoc`)">
					<ion-label class="d-flex ion-align-items-center fw-bold"><img class="mr-1" src="./img/support.svg" alt="">{{$t('Support')}}</ion-label>
				</ion-item>
				<ion-item class="ml-5" @click="openExternalLink(`https://fumbi.network/${$w18n.currentLanguage.value.value}/faq`)">
					<ion-label>FAQ</ion-label>
				</ion-item>
				<ion-item class="ml-5" @click="openExternalLink(`https://fumbi.network/${$w18n.currentLanguage.value.value}/contact`)">
					<ion-label>{{$t('Contact us')}}</ion-label>
				</ion-item>
				<hr>
				<ion-item @click="openExternalLink(`https://fumbi.network/${$w18n.currentLanguage.value.value}`)">
					<ion-label>{{$t('Fumbi Individual')}}</ion-label>
				</ion-item>
				<ion-item @click="openExternalLink(`https://fumbi.network/${$w18n.currentLanguage.value.value}/biznis`)">
					<ion-label>{{$t('Fumbi Biznis')}}</ion-label>
				</ion-item>
				<ion-item @click="openExternalLink(`https://academy.fumbi.network/`)">
					<ion-label>{{$t('Fumbi Academy')}}</ion-label>
				</ion-item>

				<ion-item v-if="!isLoggedIn">
					<ion-label class="m"><ion-button color="primary" expand="block" @click="login()">{{$t('Login')}}</ion-button></ion-label>
				</ion-item>

				<ion-item v-if="isLoggedIn" @click="openExternalLink(`https://my.fumbi.network/member/index/#/`)">
					<ion-label>Dashboard</ion-label>
				</ion-item>

				<ion-item v-if="isLoggedIn">
					<ion-label class="m"><ion-button color="primary" expand="block" @click="logout()">{{$t('Logout')}}</ion-button></ion-label>
				</ion-item>

				<!-- <ion-item @click="openExternalLink(`https://fumbi.network/${$w18n.currentLanguage.value.value}`)">
					<ion-label>{{$t('Individual clients')}}</ion-label>
				</ion-item>
				<ion-item @click="openExternalLink(`https://fumbi.network/${$w18n.currentLanguage.value.value}`)">
					<ion-label>{{$t('Companies and entrepreneurs')}}</ion-label>
				</ion-item>
				<ion-item @click="openExternalLink(`https://fumbi.network/${$w18n.currentLanguage.value.value}`)">
					<ion-label>Fumbi Academy</ion-label>
				</ion-item> -->
				<ion-item class="languages">
					<ion-label class="d-flex ion-align-items-center" @click="openPopover">
						<span :class="`flag ${$w18n.currentLanguage.value.flag}`" :style="{ backgroundImage: `url('${bg}')` }"></span><strong>{{$w18n.currentLanguage.value.title}}</strong>
						<ion-icon class="ml-1" :icon="caretDown"></ion-icon>
					</ion-label>
				</ion-item>
			</ion-list>
		</ion-content>
	</ion-menu>
</template>

<script>
import { popoverController, menuController } 	from '@ionic/vue'
import { mapGetters} 			from 'vuex'
import { caretDown } 			from 'ionicons/icons'
import APopover 				from '@/plugins/w/w18n/components/a-language-popover.vue'
import bg 						from '@/plugins/w/w18n/components/img/flags.png'
import wAxios from '@/plugins/w/axios'

const api = {
	getCategories: () => wAxios.get_auth_data('/v1/filters')
}

export default {
	props: {
		contentId: {
			required: true,
			type: String
		}
	},

	data() {
		return {
			caretDown,
			bg,
			categories: [],
		}
	},

	computed: {
		...mapGetters('wAuth', [
			'user',
			'isLoggedIn'
		])
	},

	async created() {
		this._loadData()
		
		this.eventBus.on('language-changed', this._loadData)
	},

	beforeUnmount() {
		this.eventBus.off('language-changed', this._loadData)
	},

	methods: {
		async _loadData() {
			try {
				this.categories = await api.getCategories()
			} catch(error) {
				this.categories = error
			}
		},

		closeMenu() {
			menuController.close()
		},

		openExternalLink(link) {
			window.open(link)
			menuController.close()
		},

		async openPopover(ev) {
			const popover = await popoverController.create({
				component: APopover,
				cssClass: 'languages-popover-mobile',
				event: ev,
				translucent: true
			})

			await popover.present()
		},

		login() {
			const redirectParam = `${document.location.origin}/login-successful/${encodeURIComponent(this.$route.path)}`
			const fumbiLoginUrl	= `${process.env.VUE_APP_FUMBI_LOGIN_URL}/externalSignIn/index?redirectUrl=${encodeURIComponent(encodeURI(redirectParam))}&clientId=${process.env.VUE_APP_FUMBI_LOGIN_CLIENT_ID}`
			
			window.location.assign(fumbiLoginUrl)
		},

		async logout() {
			await this.$store.dispatch('wAuth/logout')
			window.open(location.origin, "_self")
		}
	},

	watch: {
		'$route'() {
			menuController.isOpen()
				.then(isOpened => {
					if (isOpened) menuController.close()
				})
		}
	}
}
</script>

<style lang="sass" scoped>
ion-header,
ion-toolbar
	min-height: 0
	--min-height: 0
	--border-style: none
	--padding-top: 0
	--padding-bottom: 0
	--padding-end: 0
	--padding-start: 0
	padding: 0

ion-content
	--padding-start: 16px

	.header
		padding-top: 7.8px !important

	.languages
		padding-top: 15px

.background-wrapper
	background-image: url('./img/menu_bg.png')
	background-position: center right
	background-size: cover
	
hr
	background-color: #e6e6e6

	&.header-underline
		margin: 10px auto 15px !important

ion-item
	--background: transparent
	--padding-start: 0
	font-size: 16px
	--min-height: unset

	&.is-active ion-label
		color: var(--ion-color-primary)

	ion-label
		padding: 0
		margin: 5px

	.m
		margin: 15px 0 0

ion-icon
	color: #565859

ion-label
	display: flex !important

.logo
	height: 35px

.flag
	margin-left: -15px
	display: inline-block
	width: 52px
	height: 39px
	transform: scale(0.4)

	&.sk
		background-position: 0 -8569px

	&.en
		background-position: 0 -3444px

	&.cz
		background-position: 0 -2337px

	&.pl
		background-position: 0 -7626px
</style>