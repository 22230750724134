<template>
	<div class="width-100">
		<ion-grid class="container relative">
			<ion-row class="relative index">
				<ion-col size="12" class="ion-no-padding">

					<h1 class="fw-bold">ACADEMY</h1>

					<h2 class="fw-thin mt-0">{{$t('All about cryptocurrencies and blockchain')}}</h2>

					<!-- <div class="text mb-3 hidden-sm">
						<strong>{{$t('Create an account with Fumbi')}}</strong> {{$t('and you have free available as well')}} <strong>Fumbi Academy</strong>, {{$t('where we will teach you all about cryptocurrencies and investing.')}}
					</div> -->
					<div class="text mb-3">
						{{$t('Vitajte vo')}} <strong>{{$t('Fumbi Academy')}}</strong>{{$t('. Ak ste nevedeli, ako fungujú')}} <strong>{{$t('kryptomeny')}}</strong>, <strong>NFT</strong> {{$t('alebo')}} <strong>blockchain</strong>{{$t(', tak ste na správnom mieste. Zaregistrujte sa')}} <strong>{{$t('bezplatne')}}</strong> {{$t('a investujte svoj čas do vzdelávania.')}}
					</div>

					<!-- <div class="text mb-3 visible-sm">
						{{$t('We will teach you everything about')}} <br> {{$t('cryptocurrencies and investments.')}}
					</div> -->

					<ion-button class="br-0" @click="login()" v-if="!isLoggedIn">{{$t('Easy registration')}}</ion-button>
					<ion-button class="br-0" @click="$router.push({name: 'lessons'})" v-else>{{$t('Browse themes')}}</ion-button>

					<div class="video-wrapper ion-align-items-center hidden-sm">

						<div class="image-wrapper relative clickable" @click="openModal()">
							<img src="./img/video.png" alt="video">
							<img class="play" src="./img/play.svg" alt="play">
						</div>

						<div class="description relative">
							{{$t('See the first crypto-investment TV commercial in Europe.')}}
						</div>
					</div>

				</ion-col>
			</ion-row>

			<figure>
				<img src="./img/academy-hero-background.svg" alt="academy">
				<div class="after-image"></div>
				<img class="hero-img" src="./img/academy-hero-img.svg" alt="hero-img">
			</figure>
		</ion-grid>

		<div class="video-wrapper ion-align-items-center visible-sm">
			<div class="image-wrapper relative clickable" @click="openModal()">
				<img src="./img/video.png" alt="video">
				<img class="play" src="./img/play.svg" alt="play">
			</div>

			<div class="description relative">
				{{$t('See the first crypto-investment TV commercial in Europe.')}}
			</div>
		</div>
	</div>
</template>

<script>
import AVideoModal 			from "./a-video-modal.vue"
import { modalController } 	from '@ionic/vue'
import { mapGetters} 		from 'vuex'

export default {
	computed: {
		...mapGetters('wAuth', [
			'isLoggedIn'
		])
	},

	methods: {
		async openModal() {
			const modal = await modalController
				.create({
					component: AVideoModal,
					cssClass: 'homepage-video-modal'
				})
			return modal.present()
		},

		login() {
			const redirectParam = `${document.location.origin}/login-successful/${encodeURIComponent(this.$route.path)}`
			const fumbiLoginUrl	= `${process.env.VUE_APP_FUMBI_LOGIN_URL}/externalSignIn/index?redirectUrl=${encodeURIComponent(encodeURI(redirectParam))}&clientId=${process.env.VUE_APP_FUMBI_LOGIN_CLIENT_ID}`
			
			window.location.assign(fumbiLoginUrl)
		}
	}
}
</script>

<style lang="sass" scoped>
.container
	padding-top: 100px
	padding-bottom: 0

	ion-button
		--box-shadow: 0px 9px 40px rgba(0, 0, 0, 0.15)
		--background: #398ce0 !important
		--background-hover: #4d98e3 !important
		--background-focused: #4d98e3 !important
		--background-activated: #327bc5 !important

	h2
		// color: #005FC0
		color: #398ce0

	@media (max-width: 767px)
		padding-top: 50px
		padding-bottom: 50px

		h2
			font-size: 18px

.index 
	z-index: 1

figure
	margin: 0
	position: absolute
	left: 30%
	top: 0
	height: 100%
	display: flex

	.after-image
		background-color: #398ce0
		height: 100%
		width: 1500px
		margin-left: -5px
		
	img 
		display: block
		height: 100%
		max-width: unset
	
	.hero-img
		position: absolute
		left: 300px 
		bottom: 50%
		transform: translateY(50%)
		width: 530px
		height: auto
		z-index: 1

		@media (max-width: 1249px)
			width: 450px

		@media (max-width: 1099px)
			display: none

		@media (max-width: 767px)
			display: block
			left: 200px
			width: 250px

		@media (max-width: 649px)
			display: none

	@media (max-width: 767px)
		// left: 20%
		// height: 346px
		z-index: -1
	@media (max-width: 564px)
		// left: 20%

.text 
	max-width: 470px
	line-height: 26px

.video-wrapper 
	margin-top: 119px
	padding-top: 25px
	padding-bottom: 34px
	border-top: 1px solid rgba(0, 0, 0, 0.1)
	border-bottom: 1px solid rgba(0, 0, 0, 0.1)

	@media (min-width: 768px) 
		display: flex
		.description
			max-width: 190px

	.image-wrapper
		margin-right: 36px
		width: 222px
		z-index: 1

		.play
			position: absolute
			left: 50%
			top: 50%
			transform: translate(-50%, -50%)
			width: 78px
			height: 78px

	.description 
		color: #2C2E30
		font-size: 14px
		line-height: 18px
		z-index: 1
	
	@media (max-width: 767px)
		margin-top: 0
		padding: 0 20px 0
		text-align: center
		border: none

		.image-wrapper
			padding-top: 25px 
			border-top: 1px solid rgba(0, 0, 0, 0.1)

		.description
			padding-bottom: 25px 
			border-bottom: 1px solid rgba(0, 0, 0, 0.1)

		.image-wrapper,
		.description
			width: 100%

.visible-sm	
	display: none

@media (max-width: 767px)
	.hidden-sm
		display: none
	.visible-sm
		display: block
	.video-wrapper .description
		font-size: 16px
		margin-top: 10px
	h1	
		margin-top: 0
		font-size: 28px
		line-height: 28px
	h2
		line-height: 28px
		max-width: 70%
	.text
		max-width: 50%

	ion-button
		box-shadow: 0px 9px 40px rgba(0, 0, 0, 0.15)

@media (max-width: 485px)
	.text
		max-width: 70%
</style>