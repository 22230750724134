<template>
	<ion-page>
		<W-aos-content class="ion-no-padding relative">
			<div class="container px-0 py-5">
				<ion-grid>
					<ion-row class="width-100">
						<!-- Side menu -->
						<ion-col class="sidebar" size="12" size-md="4" size-lg="3">
							<div class="order-filter-wrapper mobile-hidden d-flex flex-direction-column pb-1">

								<ion-item lines="none" pointer class="mt-1" @click="$router.replace({name: 'profile', params: {profileTab: 'user-form'}})" :class="{'active': activeTab === 'user-form'}">
									<img src="../img/user.svg" alt="">
									<ion-label>{{$t('Particulars')}}</ion-label>
								</ion-item>

								<ion-item lines="none" pointer @click="$router.replace({name: 'profile', params: {profileTab: 'user-themes'}})" :class="{'active': activeTab === 'user-themes'}">
									<img src="../img/lessons.svg" alt="">
									<ion-label>{{$t('My lessons')}}</ion-label>
								</ion-item>

								<ion-item lines="none" pointer @click="$router.replace({name: 'profile', params: {profileTab: 'user-liked'}})" :class="{'active': activeTab === 'user-liked'}">
									<img src="../img/heart.svg" alt="">
									<ion-label>{{$t('Liked')}}</ion-label>
								</ion-item>

								<!-- Theme filter -->
								<div v-if="activeTab === 'user-themes'" class="border-bottom"></div>

								<ion-item v-if="activeTab === 'user-themes'" lines="none" pointer @click="filter = 'all'" class="mt-2" :class="{'active': filter === 'all'}">
									<img src="./../img/all.svg" alt="">
									<ion-label>{{$t('All')}}</ion-label>
								</ion-item>

								<ion-item v-if="activeTab === 'user-themes'" lines="none" pointer @click="filter = 'finished'" :class="{'active': filter === 'finished'}">
									<img src="../img/finished.svg" alt="">
									<ion-label>{{$t('Finished')}}</ion-label>
								</ion-item>

								<ion-item v-if="activeTab === 'user-themes'" lines="none" pointer @click="filter = 'started'" :class="{'active': filter === 'started'}">
									<img src="../img/started.svg" alt="">
									<ion-label>{{$t('Started')}}</ion-label>
								</ion-item>
							</div>
						</ion-col>

						<!-- Main content -->
						<ion-col class="main-content" size="12" size-md="8" size-lg="9">
							<ion-col class="theme-wrapper" size="12" size-md="8" size-lg="9">
								<h1 class="mt-0 ml-1">{{$t('Profile')}}</h1>
								<A-user-form 	v-if="activeTab === 'user-form'"  @openModal="openModal" class="user-form" />
								<A-user-themes 	v-if="activeTab === 'user-themes'" @openModal="openModal" class="user-liked" :filter="filter" />
								<A-user-liked 	v-if="activeTab === 'user-liked'" @openModal="openModal" class="user-liked" />
							</ion-col>
						</ion-col>
					</ion-row>
				</ion-grid>
			</div>

			<A-fumbi-socials />
			<A-footer />
		</W-aos-content>
	</ion-page>
</template>

<script>
import { modalController } 	from '@ionic/vue'
import AFooter 				from '@/plugins/appfumbi/_layout/footer/a-footer.vue'
import AUserForm 			from './components/a-user-form.vue'
import AUserThemes 			from './components/a-user-themes.vue'
import AUserLiked			from './components/a-user-liked.vue'
import AFumbiSocials		from "@/plugins/appfumbi/views/home/_components/a-fumbi-socials.vue"
import ProfileModal 		from './components/a-profile-modal.vue'

import { 
	person,
	personOutline, 
	school,
	schoolOutline,
	medal,
	medalOutline,
	heart,
	heartOutline,
	settings,
	settingsOutline
} from 'ionicons/icons'

export default {
	components: {
		AFooter,
		AUserForm,
		AUserThemes,
		AUserLiked,
		AFumbiSocials
	},

	data() {
		return {
			person,
			personOutline,
			school,
			schoolOutline,
			medal,
			medalOutline,
			heart,
			heartOutline,
			settings,
			settingsOutline,
			activeTab: 'user-form',
			filter: 'all'
		}
	},

	ionViewWillEnter() {
		this._changeTabBasedOnUrlParams()
	},

	methods: {
		_changeTabBasedOnUrlParams() {
			this.activeTab = this.$route.params.profileTab ? this.$route.params.profileTab : 'user-form'
			this.$store.dispatch('wAuth/userInfo')
		},

		async openModal() {
			this.ProfileModal = await modalController.create({
				component: ProfileModal,
				componentProps: {
					activeTab: this.activeTab,
					filter: this.filter
				}
			})

			this.ProfileModal.present()
			const { data } = await this.ProfileModal.onDidDismiss()

			if (!data) return

			const { activeTab, filter } = data

			this.$router.replace({name: 'profile', params: {profileTab: activeTab}})
			this.filter	= filter
		}
	},

	watch: {
		'$route'() {
			if (this.$route.name == 'profile') this._changeTabBasedOnUrlParams()
		}
	}
}
</script>

<style lang="sass" scoped>
.border-bottom
	margin: 7px 0
	width: 100%
	height: 0
	border-bottom: 1px solid rgba(0, 0, 0, .1)
	
.sidebar
	ion-item
		--padding-start: 15px
		--inner-padding-start: 0

		ion-label
			font-size: 16px
			font-weight: 500
			color: inherit
		
	.order-filter-wrapper
		position: relative
		padding-bottom: 15px

		ion-item
			&.active
				--background: var(--ion-color-primary)
				--border-radius: 4px
				color: white
			
			img
				margin-right: 15px

	.category-wrapper

		ion-item
			border-bottom: 1px solid rgba(0, 0, 0, .1)

		ion-label
			&.active
				color: var(--ion-color-primary)
</style>
