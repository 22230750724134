import detect from '../utils/detector'

export class AOS {
	public options = {
		offset: 120,
		delay: 0,
		easing: 'ease',
		duration: 400,
		once: false,
		mirror: false,
		anchorPlacement: 'top-bottom',
		startEvent: 'DOMContentLoaded',
		animatedClassName: 'aos-animate',
		initClassName: 'aos-init',
		useClassNames: false,
		disableMutationObserver: false,
		throttleDelay: 99,
		debounceDelay: 50
	}

	private elements
	private currentContent

	constructor(options) {
		this.options = Object.assign(this.options, options)

		options.router.afterEach(() => this._loadElements())

		document
			.querySelector('body')
			.setAttribute('data-aos-easing', options.easing)

		document
			.querySelector('body')
			.setAttribute('data-aos-duration', options.duration)

		document.querySelector('body').setAttribute('data-aos-delay', options.delay)

		window.addEventListener('resize', () => this.refresh(), true)
		window.addEventListener('orientationchange', () => this.refresh(), true)
	}

	public onScroll(offsetTop = 0) {
		this.elements.forEach((el) => this._applyClasses(el, offsetTop))

		const elements 	= this._getElements()

		if (elements.length !== this.elements.length) {
			this._loadElements()
		}
	}

	public async refresh() {
		const ionContent = Array.from(document.getElementsByTagName('ion-content'))?.find(element => element?.classList?.contains('w-aos-content'))
		const ionContentScrollTop = ionContent?.shadowRoot?.children[1]?.scrollTop

		await this._loadElements()
		this.onScroll(ionContentScrollTop)
	}

	private _loadElements(iteration = 0) {
		if (iteration > 20) return

		return new Promise((resolve) => {
			setTimeout(() => {
				const contents 		= Array.from(document.getElementsByClassName('w-aos-content'))
				this.currentContent = contents.find(content => content.getBoundingClientRect().height > 0)
	
				if (!this.currentContent) {
					return this._loadElements(iteration + 1)
				}
	
				const contentHeight = this.currentContent.getBoundingClientRect().height
				const elements 		= this._getElements()
				this.elements 		= this._prepareElements(elements, contentHeight)

				resolve(true)
			}, 100)
		})
	}

	private _prepareElements(elements, contentHeight) {
		elements.forEach((el) => {
			const mirror 	= this._getInlineOption(el.node, 'mirror', this.options.mirror)
			const once 		= this._getInlineOption(el.node, 'once', this.options.once)
			const id 		= this._getInlineOption(el.node, 'id')
			
			const customClassNames = this.options.useClassNames && el.node.getAttribute('data-aos')
		
			const animatedClassNames = [this.options.animatedClassName]
				.concat(customClassNames ? customClassNames.split(' ') : [])
				.filter(className => typeof className === 'string')
		
			if (this.options.initClassName) {
				el.node.classList.add(this.options.initClassName)
			}
		
			el.position = {
				in: this._getPositionIn(el.node, contentHeight),
				out: mirror && this._getPositionOut(el.node)
			}
		
			el.options = {
				once,
				mirror,
				animatedClassNames,
				id
			}
		})
		
		return elements
	}

	private _getElements() {
		const elements = this.currentContent.querySelectorAll('[data-aos]')
		return Array.prototype.map.call(elements, node => ({ node }))
	}

	private _getInlineOption(el, key, fallback = null) {
		const attr = el.getAttribute('data-aos-' + key)

		if (typeof attr !== 'undefined') {
			if (attr === 'true') {
				return true
			} else if (attr === 'false') {
				return false
			}
		}

		return attr || fallback
	}

	private _getPositionIn(el, contentHeight = null) {
		const windowHeight 			= contentHeight ? contentHeight : window.innerHeight
		const anchor 				= this._getInlineOption(el, 'anchor')
		const inlineAnchorPlacement = this._getInlineOption(el, 'anchor-placement')
		const additionalOffset 		= Number(this._getInlineOption(el, 'offset', inlineAnchorPlacement ? 0 : this.options.offset))

		const anchorPlacement 		= inlineAnchorPlacement || this.options.anchorPlacement
		let finalEl 				= el
	
		if (anchor && document.querySelectorAll(anchor)) {
			finalEl = document.querySelectorAll(anchor)[0]
		}
	
		let triggerPoint = this._getOffset(finalEl).top - windowHeight
	
		switch (anchorPlacement) {
		case 'top-bottom':
			break
		case 'center-bottom':
			triggerPoint += finalEl.offsetHeight / 2
			break
		case 'bottom-bottom':
			triggerPoint += finalEl.offsetHeight
			break
		case 'top-center':
			triggerPoint += windowHeight / 2
			break
		case 'center-center':
			triggerPoint += windowHeight / 2 + finalEl.offsetHeight / 2
			break
		case 'bottom-center':
			triggerPoint += windowHeight / 2 + finalEl.offsetHeight
			break
		case 'top-top':
			triggerPoint += windowHeight
			break
		case 'bottom-top':
			triggerPoint += windowHeight + finalEl.offsetHeight
			break
		case 'center-top':
			triggerPoint += windowHeight + finalEl.offsetHeight / 2
			break
		}
	
		return triggerPoint + additionalOffset
	}
	
	private _getPositionOut(el) {
		const anchor 			= this._getInlineOption(el, 'anchor')
		const additionalOffset 	= this._getInlineOption(el, 'offset', this.options.offset)
		let finalEl = el
	
		if (anchor && document.querySelectorAll(anchor)) {
			finalEl = document.querySelectorAll(anchor)[0]
		}
	
		const elementOffsetTop = this._getOffset(finalEl).top
	
		return elementOffsetTop + finalEl.offsetHeight - additionalOffset
	}

	private _getOffset(el) {
		let _x = 0
		let _y = 0
	
		while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
			_x += el.offsetLeft - (el.tagName != 'BODY' ? el.scrollLeft : 0)
			_y += el.offsetTop - (el.tagName != 'BODY' ? el.scrollTop : 0)
			el = el.offsetParent
		}
	
		return {
			top: _y,
			left: _x
		}
	}

	private _applyClasses(el, offsetTop) {
		const { options, position, node } = el

		const hide = () => {
			if (!el.animated) return

			this._removeClasses(node, options?.animatedClassNames)
			this._fireEvent('aos:out', node)

			if (el.options?.id) {
				this._fireEvent(`aos:in:${el.options?.id}`, node)
			}

			el.animated = false
		}

		const show = () => {
			if (el.animated) return

			this._addClasses(node, options?.animatedClassNames)
			this._fireEvent('aos:in', node)

			if (el.options?.id) {
				this._fireEvent(`aos:in:${el.options?.id}`, node)
			}

			el.animated = true
		}

		if (options?.mirror && offsetTop >= position.out && !options?.once) {
			hide()
		} else if (offsetTop >= position.in) {
			show()
		} else if (el.animated && !options?.once) {
			hide()
		}
	}

	private _addClasses(node, classes) {
		classes && classes.forEach(className => node.classList.add(className))
	}


	private _removeClasses(node, classes) {
		classes && classes.forEach(className => node.classList.remove(className))
	}
	
	private _fireEvent (eventName, data) {
		let customEvent

		if (detect.ie11()) {
			customEvent = document.createEvent('CustomEvent')
			customEvent.initCustomEvent(eventName, true, true, { detail: data })
		} else {
			customEvent = new CustomEvent(eventName, {
				detail: data
			})
		}

		return document.dispatchEvent(customEvent)
	}
}