<template>
	<ion-grid class="container"> 
		<ion-row>

			<ion-col size="12" class="text-center mb-5" data-aos="fade-up">
				<!-- <span class="headline">{{$t('Our partners')}}</span> -->
				<h2>{{$t('We work with leaders')}}</h2>
			</ion-col>

			<ion-col class="partners d-flex ion-justify-content-center mb-5" data-aos="fade-up">
				<img class="px-4" src="./img/partners-blockchain.png" alt="blockchain">
				<img class="px-4" src="./img/partners-sgs.png" alt="sgs">
				<img class="px-4" src="./img/partners-ledger.png" alt="financer">
				<img class="px-4" src="./img/partners-fintree.png" alt="ledger">
				<img class="px-4" src="./img/partners-fintechhub.png" alt="ledger">
				<img class="px-4" src="./img/partners-FT.png" alt="ledger">
				<img class="px-4" src="./img/partners-sapie.jpeg" alt="ledger">
			</ion-col>

			<ion-col size="12" class="logos-title text-center mt-10 mb-5" data-aos="fade-up">
				<!-- <span class="headline">{{$t('It is written about us')}}</span> -->
				<h2>{{$t('They wrote about us')}}</h2>
			</ion-col>

			<ion-col class="logos d-flex ion-justify-content-center" data-aos="fade-up">
				<img class="px-2 mb-5" :src="require(`./img/${$t('partner1')}`)" alt="">
				<img class="px-2 mb-5" :src="require(`./img/${$t('partner2')}`)" alt="">
				<img class="px-2 mb-5" :src="require(`./img/${$t('partner3')}`)" alt="">
				<img class="px-2 mb-5" :src="require(`./img/${$t('partner4')}`)" alt="">
				<img class="px-2 mb-5" :src="require(`./img/${$t('partner5')}`)" alt="">
				<img class="px-2 mb-5" :src="require(`./img/${$t('partner6')}`)" alt="">
				<img class="px-2 mb-5" :src="require(`./img/${$t('partner7')}`)" alt="">
				<img class="px-2 mb-5" :src="require(`./img/${$t('partner8')}`)" alt="">
				<img class="px-2 mb-5" :src="require(`./img/${$t('partner9')}`)" alt="">
				<img class="px-2 mb-5" :src="require(`./img/${$t('partner10')}`)" alt="">
				<img class="px-2 mb-5" :src="require(`./img/${$t('partner11')}`)" alt="">
				<img class="px-2 mb-5" :src="require(`./img/${$t('partner12')}`)" alt="">
				<img class="px-2 mb-5" :src="require(`./img/${$t('partner13')}`)" alt="">
				<img v-if="$w18n.currentLanguage.value.value != 'cs'" class="px-2 mb-5" :src="require(`./img/${$t('partner14')}`)" alt="">
			</ion-col>

		</ion-row>
	</ion-grid>
</template>

<script>
export default {
	
}
</script>

<style lang="sass" scoped>
.container
	padding-top: 130px
	border-bottom: 1px solid rgba(#000000, .1)
.partners
	flex-wrap: wrap
	img
		height: 60px
		margin: 26px 0

.logos
	flex-wrap: wrap
	&-title
		margin-top: 50px
	img
		height: 75px
@media (max-width: 1023px)
	.container 
		padding-top: 100px
@media (max-width: 767px)
	.container 
		padding-top: 50px
	.partners
		img
			height: 50px
			margin: 13px 0
	.logos
		img
			height: 50px
			margin-bottom: 20px !important
</style>