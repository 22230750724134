<template>
	<div class="ion-padding">
		<div class="wrapper">
			<ion-spinner name="crescent"></ion-spinner>
		</div>
	</div>
</template>

<style lang="sass" scoped>
.wrapper
	height: 200px
	display: flex
	justify-content: center
	align-items: center

	ion-spinner
		--color: var(--ion-color-primary-contrast)
		height: 50px
		width: 50px

@media only screen and (max-width: 739px)
	.wrapper
		height: calc(100vh - 250px)
</style>