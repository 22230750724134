<template>
	<div class="ion-padding">
		<div class="d-flex ion-justify-content-start mb-2">
			<ion-skeleton-text class="avatar"></ion-skeleton-text>
			<ion-skeleton-text class="text"></ion-skeleton-text>
		</div>

		<div class="d-flex ion-justify-content-start mb-2">
			<ion-skeleton-text class="avatar"></ion-skeleton-text>
			<ion-skeleton-text class="text"></ion-skeleton-text>
		</div>

		<div class="d-flex ion-justify-content-start mb-2">
			<ion-skeleton-text class="avatar"></ion-skeleton-text>
			<ion-skeleton-text class="text"></ion-skeleton-text>
		</div>

		<div class="d-flex ion-justify-content-start mb-2">
			<ion-skeleton-text class="avatar"></ion-skeleton-text>
			<ion-skeleton-text class="text"></ion-skeleton-text>
		</div>

		<div class="d-flex ion-justify-content-start mb-2">
			<ion-skeleton-text class="avatar"></ion-skeleton-text>
			<ion-skeleton-text class="text"></ion-skeleton-text>
		</div>
		
	</div>
</template>

<style lang="sass" scoped>
.avatar
	width: 40px
	height: 40px
	border-radius: 20px
	margin-right: 20px

.text
	width: calc(100% - 60px)
	height: 20px
	margin-top: 10px
</style>