<template>
	<ion-page>
		<ion-header>
			<ion-toolbar>
				<ion-title>{{$t("Lessons filter")}}</ion-title>
				<ion-icon class="size-l mr-1" slot="end" :icon="close" @click="closeModal()"></ion-icon>
			</ion-toolbar>
		</ion-header>

		<ion-content class="ion-padding">
			<div class="content-wrapper d-flex flex-direction-column ion-justify-content-between">

				<div class="width-100">
					<div class="order-filter width-100 pb-1">

						<ion-item lines="none" @click="sortLessonsBy('all')" :class="{'active' : filterSortedBy == 'all'}" class="mt-1">
							<img src="../img/all-icon.svg" alt="">
							<ion-label>{{$t("All")}}</ion-label>
						</ion-item>

						<ion-item lines="none" @click="sortLessonsBy('date')" :class="{'active' : filterSortedBy == 'date'}">
							<img src="../img/date-icon.svg" alt="">
							<ion-label>{{$t("Newest")}}</ion-label>
						</ion-item>

						<ion-item lines="none" @click="sortLessonsBy('like')" :class="{'active' : filterSortedBy == 'like'}">
							<img src="../img/like-icon.svg" alt="">
							<ion-label>{{$t("Most popular")}}</ion-label>
						</ion-item>

					</div>

					<h4>{{$t("Level")}}</h4>
					<div class="width-100">
						<div class="d-flex ion-wrap ion-align-items-center">
							<ion-chip class="green-outline" @click="toggleTagFilter(1)" :class="{active : filterAvailableTags.includes(1) }">
								• {{$t('Beginner')}}
								<ion-icon v-if="filterAvailableTags.includes(1)" :icon="checkmark"></ion-icon>
								<ion-icon v-else :icon="closeOutline"></ion-icon>
							</ion-chip>

							<!-- <ion-chip class="yellow-outline" @click="toggleTagFilter(2)" :class="{active : filterAvailableTags.includes(2) }">
								• {{$t('intermediate')}}
								<ion-icon v-if="filterAvailableTags.includes(2)" :icon="checkmark"></ion-icon>
								<ion-icon v-else :icon="closeOutline"></ion-icon>
							</ion-chip> -->

							<ion-chip class="orange-outline" @click="toggleTagFilter(2)" :class="{active : filterAvailableTags.includes(2) }">
								• {{$t('Advanced')}}
								<ion-icon v-if="filterAvailableTags.includes(2)" :icon="checkmark"></ion-icon>
								<ion-icon v-else :icon="closeOutline"></ion-icon>
							</ion-chip>

							<!-- <ion-chip class="red-outline" @click="toggleTagFilter(4)" :class="{active : filterAvailableTags.includes(4) }">
								• {{$t('More advanced')}}
								<ion-icon v-if="filterAvailableTags.includes(4)" :icon="checkmark"></ion-icon>
								<ion-icon v-else :icon="closeOutline"></ion-icon>
							</ion-chip> -->

							<ion-chip class="black-outline" @click="toggleTagFilter(3)" :class="{active : filterAvailableTags.includes(3) }">
								• {{$t('Expert')}}
								<ion-icon v-if="filterAvailableTags.includes(3)" :icon="checkmark"></ion-icon>
								<ion-icon v-else :icon="closeOutline"></ion-icon>	
							</ion-chip>
						</div>
					</div>
					
					<h4>{{$t("Themes")}}:</h4>
					<div class="side-menu width-100">
						<ion-item v-for="category in categories" :key="category.id" @click="changeCategory(category.slug)">
							<ion-label :class="{'active' : filterActiveTab == category.slug}">
								{{ category.name }}
							</ion-label>
							<ion-icon v-if="filterActiveTab == category.slug" slot="end" class="size-l" color="primary" :icon="checkmark"></ion-icon>
						</ion-item>
					</div>
				</div>

				<div class="d-flex ion-justify-content-center">
					<ion-button expand="block" color="primary" fill="solid"		@click="saveModal()">Uožiť</ion-button>
					<ion-button expand="block" color="primary" fill="outline" 	@click="closeModal()">Zrušit</ion-button>
				</div>

			</div>
		</ion-content>
	</ion-page>
</template>

<script>
import { close, closeOutline, checkmark } 	from 'ionicons/icons'
import { modalController } 					from '@ionic/vue'

export default {
	props: {
		availableTags: {
			type: Object,
			required: true
		},

		activeTab: {
			type: String,
			required: true
		},

		sortedBy: {
			type: String,
			required: true
		},

		categories: {
			type: Object,
			required: true
		}
	},

	data() {
		return {
			close,
			closeOutline,
			checkmark,
			filterAvailableTags: [],
			filterActiveTab: 'all',
			filterSortedBy: 'all',
		}
	},

	created() {
		this.filterAvailableTags 	= [...this.availableTags ]
		this.filterActiveTab 		= this.activeTab
		this.filterSortedBy 		= this.sortedBy
	},

	methods: {
		async sortLessonsBy(sortBy) {
			this.filterSortedBy = sortBy
			// if (sortBy == "all") this.changeCategory('all')
		},

		changeCategory(name) {
			if (this.filterActiveTab == name) {
				this.filterActiveTab = 'all'
			} else {
				this.filterActiveTab = name
			}
		},

		toggleTagFilter(difficulty) {
			if (this.filterAvailableTags.includes(difficulty)) {
				this.filterAvailableTags = this.filterAvailableTags.filter(item => item != difficulty)
			} else {
				this.filterAvailableTags.push(difficulty)
			}
		},

		closeModal() {
			modalController.dismiss()
		},

		saveModal() {
			modalController.dismiss({
				availableTags: this.filterAvailableTags,
				activeTab: this.filterActiveTab,
				sortedBy: this.filterSortedBy,
			})
		},
	}
}
</script>

<style lang="sass" scoped>
ion-header
	ion-toolbar
		--min-height: 50px
		// --background: var(--ion-color-primary)
		// color: white

		ion-title
			font-weight: 500
			// letter-spacing: 1px

ion-button
	margin: 5px
	margin-top: 40px
	height: 35px
	font-size: 14px
	font-weight: 400

.content-wrapper
	min-height: 100%

.order-filter
	border-bottom: 1px solid rgba(0, 0, 0, .1)

	ion-label
		font-weight: 500

h4
	font-weight: 500

ion-item
	--padding-start: 0
	--inner-padding-end: 0

	&.active
		--background: var(--ion-color-primary)
		--border-radius: 4px
		color: white

	img
		margin: 0 10px

.side-menu
	ion-label
		margin-left: 10px !important

		&.active
			color: var(--ion-color-primary)

ion-chip
	padding-right: 10px

	&:last-child
		margin-right: 0


@media only screen and (max-width: 1000px)
	ion-label
		font-size: 14px !important
</style>