import { createStore }  from 'vuex'
import wAuth 			from '@/plugins/w/authStore/wAuth.store'

const store = createStore({
	modules: {
		wAuth
	}
})

export default store
