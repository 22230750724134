<template>
	<ion-grid class="ion-no-padding"> 
		<ion-row>

			<ion-col size="12" class="text-center mb-5" data-aos="fade-up">
				<!-- <span class="headline">{{$t('Join us')}}</span> -->
				<h2>{{$t('They said about us')}}</h2>
			</ion-col>

			<ion-col class="ion-no-padding" data-aos="fade-up">
				<Z-carousel :items-to-show="5.5" :wrap-around="false" :breakpoints="breakpoints" :settings="settings">
					<Z-slide v-for="(slide, index) in slides" :key="index">

						<div class="carousel__item" :style="`margin-top: ${cardMargin(index)}px`">
							<img class="photo mb-1" :src="slide.img" :alt="slide.title">
							<!-- <img class="photo mb-1" src="@/plugins/appfumbi/views/home/_components/img/man-silueta.png" :alt="slide.title"> -->

							<p class="name small color-primary-contrast">{{ slide.author }}</p>
							<h3 class="title color-primary-contrast">“{{$t(`testimonial-${index + 1}-title`)}}”</h3>
							<p class="testimonial small">{{$t(`testimonial-${index + 1}-text`)}}</p> 
						</div>

					</Z-slide>

					<template #addons>
						
						<Z-navigation />
						<Z-pagination />
						
					</template>
				</Z-carousel>
			</ion-col>

		</ion-row>
	</ion-grid>
</template>

<script>
import '@/plugins/w@custom/z-carousel/dist/carousel.css'
import { ZCarousel, ZSlide, ZPagination, ZNavigation } from '@/plugins/w@custom/z-carousel'

// import male from './img/man-silueta.png'
// import female from './img/woman-silueta.png'
import red from './img/testimonials-icon-red.svg'
import green from './img/testimonials-icon-green.svg'
import green2 from './img/testimonials-icon-green2.svg'
import blue from './img/testimonials-icon-blue.svg'
import orange from './img/testimonials-icon-orange.svg'
import orange2 from './img/testimonials-icon-orange2.svg'

export default {
	components: {
		ZCarousel,
		ZSlide,
		ZPagination,
		ZNavigation
	},

	data() {
		return {
			slides: [
				{
					img: blue,
					author: 'Andrej Nagy'
				},
				{
					img: green,
					author: 'Ivan Petrovič'
				},
				{
					img: red,
					author: 'Ján B.'
				},
				{
					img: orange2,
					author: 'Klaudia M.'
				},
				{
					img: green2,
					author: 'Andrea K.'
				},
				{
					img: blue,
					author: 'Martin Woska'
				},
				{
					img: orange,
					author: 'Miloslav Vonka'
				},
				{
					img: red,
					author: 'Janis Vlachopulos'
				},
			],
			settings: {
				mouseDrag: false,
				touchDrag: false,
				snapAlign: 'center',
				itemsToShow: 1.5,
				transition: 300
			},
			breakpoints: {
				768: {
					itemsToShow: 2.5,
				},
				1024: {
					itemsToShow: 3.5,
				},
				1400: {
					itemsToShow: 5.7,
				},
			},
		}
	},

	methods: {
		cardMargin(index) {
			// const margins = [180, 80, 0, 160, 40, 180]
			const margins = [50, 50]

			if (margins.length > index)
				return margins[index]

			const modifiedIndex = index % (margins.length - 1)
			return margins[modifiedIndex]
		}
	}
}
</script>

<style lang="sass" scoped>
ion-row
	padding-top: 130px
.carousel
	@media only screen and (max-width: 800px)
		.carousel__slide
			margin-top: 0 !important
		.carousel__item
			box-shadow: 0 30px 20px rgba(149,193,31, 0.5)
			&:before
				height: 70px !important
			
	.carousel__slide
		margin-top: 70px !important
		padding: 10px 10px 100px
		transition-duration: 300ms
		opacity: 0.85
		&:hover
			margin-top: -20px !important
			cursor: pointer

	@media only screen and (max-width: 800px)
		.carousel__slide
			margin-top: 0
			cursor: auto

	.carousel__item
		padding: 100px 30px 30px
		position: relative
		width: 100%
		min-height: 406px
		background-color: #8DC63F
		border-radius: 4px
		box-shadow: 0 30px 30px rgba(149,193,31, 0.5)
		
		&:before
			content: ""
			display: block
			width: 100%
			height: 200px
			position: absolute
			top: 100%
			left: 0
			background: rgba(149, 193, 31, 0.1)
		.photo
			position: absolute
			top: -40px
			left: 50%
			transform: translateX(-50%)
			height: 110px
		.name,
		.title,
		.testimonial
			margin-bottom: 26px
		p.testimonial
			color: #085200
	:deep(.carousel__slide--active)
		opacity: 1
	:deep(.carousel__pagination)
		position: absolute
		top: 0
		left: 50%
		transform: translateX(-50%)
		padding-inline-start: 0
	:deep(.carousel__pagination-button)
		width: 12px !important
		height: 12px !important
		border-radius: 6px !important
		padding: 0 !important
		background-color: #E4EFD0
	:deep(.carousel__pagination-button--active)
		background-color: var(--ion-color-primary)
	:deep(.carousel__prev)
		top: 25px
		left: calc(50% - 140px)
	:deep(.carousel__next)
		top: 25px
		right: calc(50% - 140px)
	:deep(.carousel__next),
	:deep(.carousel__prev)
		width: 46px
		height: 46px
		background-color: #fff
		border: 1px solid #e6e6e6
		transition: background-color .3s
		&:hover
			background-color: #E4EFD0
	:deep(.carousel__icon)
		color: #000
@media (max-width: 1023px)
	ion-row 
		padding-top: 90px
@media (max-width: 767px)
	ion-row 
		padding-top: 60px
</style>