<template>
	<div data-aos="fade-up">
		<ion-grid class="container">
			<ion-row>
				<ion-col size="12">
					<!-- <span class="headline">{{$t('We are on networks')}}</span> -->
					<h2>{{$t('Follow Fumbi')}}<br>{{$t('on')}} <span class="color-primary">{{$t('social networks')}}</span></h2>
					<div class="socials d-flex ion-align-items-center">
						<img src="./img/facebook.svg" alt="facebook" @click="openExternalLink('https://www.facebook.com/fumbi.networkSK')">
						<img src="./img/twitter.svg" alt="twitter" @click="openExternalLink('https://twitter.com/FumbiNetwork')">
						<img src="./img/linkedin.svg" alt="linkedin" @click="openExternalLink('https://www.linkedin.com/company/fumbi/')">
						<img src="./img/youtube.svg" alt="youtube" @click="openExternalLink('https://www.youtube.com/watch?v=3CXJZuharv4')">
						<img src="./img/instagram.svg" alt="instagram" @click="openExternalLink('https://www.instagram.com/fumbi.network/')">
					</div>
				</ion-col>
			</ion-row>
		</ion-grid>

		<!-- <ion-grid class="ion-no-padding hidden-sm">
			<ion-row>
				<ion-col class="ion-no-padding d-flex">
					<Z-carousel :items-to-show="7" :settings="settings">
						<Z-slide v-for="(slide, index) in slides" :key="index">

							<div class="carousel__item">
								<img :src="slide.img" :alt="index" @click="openExternalLink(slide.url)">
							</div>

						</Z-slide>
					</Z-carousel>
				</ion-col>
			</ion-row>
		</ion-grid> -->

		<W-loadable :items="posts" skelet="spinner" class="width-100" messageEmpty="" messageError="">
			<ion-grid class="ion-no-padding hidden-sm">
				<ion-row>
					<ion-col class="ion-no-padding d-flex">
						<Z-carousel :items-to-show="Object.keys(this.posts).length" :settings="settings">
							<Z-slide v-for="(post, index) in posts" :key="index">

								<div class="carousel__item">
									<a href="https://www.instagram.com/fumbi.network/" target="_blank"><img :src="post" :alt="index"></a>
								</div>

							</Z-slide>
						</Z-carousel>
					</ion-col>
				</ion-row>
			</ion-grid>
		</W-loadable>
	</div>
</template>

<script>
import '@/plugins/w@custom/z-carousel/dist/carousel.css'
import { ZCarousel, ZSlide } from '@/plugins/w@custom/z-carousel'
import wAxios from '@/plugins/w/axios'

const api = {
	getPosts: () => wAxios.get_auth_data(`${process.env.VUE_APP_FUMBI_API}/v1/instagram`),

}

export default {
	components: {		
		ZCarousel,
		ZSlide,
	},
	data() {
		return {
			settings: {
				mouseDrag: false,
				touchDrag: false,
				transition: 300,
				autoplay: 5000,
				itemsToShow: 7,
				wrapAround: true
			},
			posts: [],
		}
	},

	async created() {
		try {
			this.posts = await api.getPosts()
		} catch (error) {
			this.posts = {error: error}
		}
	},

	methods: {
		openExternalLink(url) {
			window.open(url)
		}
	},
}
</script>

<style lang="sass" scoped>
.container
	// padding-top: 150px
	padding-top: 50px

.socials
	position: absolute
	right: 0
	bottom: 0

	img
		cursor: pointer
		transition: opacity .3s
		&:not(:first-child)
			margin-left: 10px
		&:hover
			opacity: .7

.carousel__slide
	padding-top: 75px

	.carousel__item
		transition: transform .3s,box-shadow .3s
		width: 100%

		&:hover
			transform: translateY(-20px) scale(1.1)
			box-shadow: 0 19px 40px 6px rgb(0 0 0 / 40%)
			z-index: 1

		img
			width: 100%
			height: 100%
			object-fit: contain
			object-fit: cover
			// aspect-ratio: 1 / 1

// @media (max-width: 1023px)
// 	.container 
// 		padding-top: 120px
// @media (max-width: 767px)
// 	.container 
// 		padding-top: 70px
@media (max-width: 767px)
	.hidden-sm
		display: none
	.socials
		position: relative
</style>