<template>
	<ion-page>
		<W-aos-content class="ion-no-padding relative">
			<A-intro-section />
			<A-newest-lessons />
			<A-fumbi-advantages />
			<A-Banners />
			<A-fumbi-newsletter />
			<A-fumbi-testimonials />
			<A-partners-section  />
			<A-fumbi-socials />
			<A-footer />
		</W-aos-content>
	</ion-page> 
</template>

<script>
import AFooter 				from '@/plugins/appfumbi/_layout/footer/a-footer.vue'

import AIntroSection 		from "./_components/a-intro-section.vue"
import ANewestLessons 		from './_components/a-newest-lessons.vue'
import AFumbiAdvantages 	from "./_components/a-fumbi-advantages.vue"
import ABanners 			from "@/plugins/lib@lesson/_components/a-banners.vue"
import AFumbiNewsletter 	from "./_components/a-fumbi-newsletter.vue"
import AFumbiTestimonials 	from "./_components/a-fumbi-testimonials.vue"
import APartnersSection 	from "./_components/a-partners-section.vue"
import AFumbiSocials 		from "./_components/a-fumbi-socials.vue"


export default {	
	components: {
		AFooter,
		AIntroSection,
		ANewestLessons,
		AFumbiAdvantages,
		ABanners,
		AFumbiNewsletter,
		AFumbiTestimonials,
		APartnersSection,
		AFumbiSocials
	},

	// created() {
	// 	console.log(this.$aos)
	// }
}
</script>