import WAosContent 	from './components/w-aos-content.vue'
import { AOS } 		from './model/AOS'

import './sass/aos.scss'

export default {
	install: (app, options) => {
		// AOS.init(options)

		// app.config.globalProperties.$aos = AOS
		
		// app.component('W-aos-content', WAosContent)

		// app.config.globalProperties.$router.afterEach(() => {
		// 	setTimeout(() => app.config.globalProperties.$aos.refreshHard())
		// })

		options.router 						= app.config.globalProperties.$router
		app.config.globalProperties.$aos 	= new AOS(options)

		app.component('W-aos-content', WAosContent)
	}
}