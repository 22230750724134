<template>
	<ion-page>
		<W-aos-content class="ion-no-padding">
			<div class="container px-0 py-5">
				<ion-grid>
					<ion-row class="width-100">
						<!-- Side menu -->
						<ion-col class="sidebar" size="12" size-md="4" size-lg="3">
							<div class="order-filter-wrapper mobile-hidden d-flex flex-direction-column pb-1">
								<ion-item lines="none" pointer @click="sortLessonsBy('all')" 	:class="{'active' : sortedBy == 'all'}" class="mt-1"><img src="./img/all-icon.svg" alt=""><ion-label>{{$t('All')}}</ion-label></ion-item>
								<ion-item lines="none" pointer @click="sortLessonsBy('date')" 	:class="{'active' : sortedBy == 'date'}"><img src="./img/date-icon.svg" alt=""><ion-label>{{$t('Latest')}}</ion-label></ion-item>
								<ion-item lines="none" pointer @click="sortLessonsBy('like')" 	:class="{'active' : sortedBy == 'like'}"><img src="./img/like-icon.svg" alt=""><ion-label>{{$t('Most popular')}}</ion-label></ion-item>
								<div class="border-bottom"></div>
							</div>

							<ion-col class="category-wrapper mobile-hidden" size="12">
								<ion-item lines="none" pointer v-for="category in categories" :key="category.id" @click="changeCategory(category.slug)">
									<ion-label :class="{'active' : activeTab == category.slug}">{{ category.name }}</ion-label>
									<ion-icon v-if="activeTab == category.slug" slot="end" class="size-l" color="primary" :icon="chevronForward"></ion-icon>
									<ion-icon v-else slot="end" class="size-l" :icon="chevronForward"></ion-icon>
								</ion-item>
							</ion-col>
						</ion-col>

						<!-- Main content -->
						<ion-col class="main-content" size="12" size-md="8" size-lg="9">
							<ion-col class="d-flex flex-direction-column ion-justify-content-between" size="12">
								<div class="pl-1">
									<h2 class="mt-0">{{$t('All themes')}}</h2>
									<h4 class="large color-primary mb-0">{{$t(`With Fumbi you will gain crypto and knowledge in a few clicks.`)}}</h4>
								</div>
								<Z-top-menu class="top-menu" :difficultyFilter="true" @openModal="openLessonFilterModal" @toggledifficulty="toggleTagFilter" @changeGrid="toggleGrid" ref="topMenu" />
							</ion-col>

							<!-- Temy -->
							<ion-col class="theme-wrapper" size="12" size-md="8" size-lg="9">
								<W-loadable :items="filteredLessons" skelet="themes" class="lessons-wrapper" :messageEmpty="$t('Search does not match any lessons!')">
									<ion-row class="themes">
										<ion-col class="mb-2" v-for="lesson in filteredLessons" :key="lesson.id" size="12" :size-sm="(!grid && !gridFixed) ? 12 : 6" :size-lg="(!grid && !gridFixed) ? 12 : 4">
											<Z-lesson-card :lesson="lesson" :grid="grid" />
										</ion-col>
									</ion-row>
									<A-banners />
								</W-loadable>
							</ion-col>
						</ion-col>
					</ion-row>
				</ion-grid>
			</div>
			
			<A-fumbi-socials />
			<A-footer />
		</W-aos-content>
	</ion-page>
</template>

<script>
import { personCircleOutline, logInOutline, gridSharp, search, list, heart, time, funnel, chevronForward, closeOutline } from 'ionicons/icons'
import wAxios 				from '@/plugins/w/axios'
import { mapGetters} 		from 'vuex'
import { modalController } 	from '@ionic/vue'

import AFooter 				from '@/plugins/appfumbi/_layout/footer/a-footer.vue'
import ZTopMenu 			from '@/plugins/lib@profile/profile/components/z-top-menu.vue'
import LessonModal 			from './_components/a-lesson-filter-modal.vue'
import ABanners 			from '../_components/a-banners.vue'
import ZLessonCard 			from '../_components/z-lesson-card.vue'
import AFumbiSocials 		from "@/plugins/appfumbi/views/home/_components/a-fumbi-socials.vue"

const api = {
	getLessons: (getBy = '') 			=> wAxios.get_auth_data(`v1/lessons${getBy ? `?sort=${getBy}`: ''}`),
	getCategories: () 					=> wAxios.get_auth_data('/v1/filters'),
	userflag: (lessonId, type, value) 	=> wAxios.post_auth_data(`/v1/userflag/lesson/${lessonId}`, {type: type, value: value})
}

export default {
	components: {
		AFooter,
		ZTopMenu,
		ABanners,
		ZLessonCard,
		AFumbiSocials
	},

	data() {
		return {
			personCircleOutline,
			logInOutline,
			gridSharp,
			search,
			list,
			heart,
			time,
			funnel,
			chevronForward,
			closeOutline,
			availableTags: [],
			allLessons: [],
			categories: [],
			filteredLessons: null,
			activeTab: '',
			sortedBy: '',
			searchQuery: "",
			grid: true,
			gridFixed: false,
		}
	},

	computed: {
		...mapGetters('wAuth', [
			'user',
			'isLoggedIn'
		]),

		...mapGetters('lessons', [
			'lessons'
		]),
	},

	async created() {
		await this._loadData()
		this.filteredLessons = this.allLessons
		this.eventBus.on('language-changed', this._loadData)
	},

	beforeUnmount() {
		this.eventBus.off('language-changed', this._loadData)
	},

	mounted() {
		this.$watch(
			'$refs.topMenu.searchQuery', (search) => {
				this.searchQuery = search
				this._filterLessons()
			}
		),

		window.addEventListener('resize', () => {
			this.gridFixed = document.documentElement.clientWidth <= 575
		})
	},

	methods: {
		async sortLessonsBy(sortBy) {
			if (sortBy == 'all') {
				this.allLessons = await api.getLessons()
				// this.changeCategory('all')
			} else { 
				this.allLessons = await api.getLessons(sortBy)
			}

			this.sortedBy = sortBy
			this._filterLessons()
		},

		changeCategory(name) {
			if (this.activeTab == name) {
				this.activeTab = 'all'
			} else {
				this.activeTab = name
			}
		},

		toggleTagFilter(difficulty) {
			if (this.availableTags.includes(difficulty)) {
				this.availableTags = this.availableTags.filter(item => item != difficulty)
			} else {
				this.availableTags.push(difficulty)
			}
			this._filterLessons()
		},

		openSearchbar() {
			this.$refs.searchbar?.$el.setFocus()
		},

		toggleGrid(grid) {
			this.grid = grid
		},

		async _loadData() {
			if (this.$route.name != 'lessons') return

			this.filteredLessons = null

			this.initSortBy = this.$route.params.sortBy ?? 'all'
			this.activeTab = this.initSortBy
			this.sortedBy = this.initSortBy

			try {
				this.allLessons = await api.getLessons(this.initSortBy)
				this.categories = await api.getCategories()
				this._filterLessons()
				this.$aos.refresh()
			} catch (error) {
				this.allLessons = {error: error}
			}
		},

		_filterLessons() {
			let filteredLessons = this.allLessons

			//filter by difficulty
			filteredLessons = filteredLessons.filter(lesson => {
				if (!Object.keys(this.availableTags).length) return true
				return this.availableTags.includes(+lesson.difficulty)
			})

			//filter by active tab
			if (this.activeTab != 'all') {
				filteredLessons = filteredLessons.filter(lesson => {
					const tags = lesson.tags.map(tag => tag.toLowerCase())
					return tags.includes(this.activeTab)
				})
			}

			//filter by search...
			let normalizedSearchQuery = this.searchQuery.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, "")

			if (this.searchQuery.length > 2) {
				filteredLessons = filteredLessons.filter(lesson => {
					const normalizedDesc = lesson.name.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, "")
					// console.log(normalizedDesc, normalizedSearchQuery, "afsdfd")
					return normalizedDesc.includes(normalizedSearchQuery)
				})
			}
			this.filteredLessons = filteredLessons
		},

		async openLessonFilterModal() {
			this.lessonModal = await modalController.create({
				component: LessonModal,
				componentProps: {
					availableTags: this.availableTags,
					activeTab: this.activeTab,
					sortedBy: this.sortedBy,
					categories: this.categories
				}
			})

			this.lessonModal.present()
			const { data } = await this.lessonModal.onDidDismiss()

			if (!data) return

			const { availableTags, activeTab, sortedBy } = data

			this.availableTags 					= availableTags
			this.$refs.topMenu.availableTags	= availableTags
			this.activeTab 						= activeTab
			this.sortedBy 						= sortedBy

			this.sortLessonsBy(this.sortedBy)
			this._filterLessons()
		}
	},

	watch: {
		searchQuery() {
			this._filterLessons()
		},

		activeTab() {
			this._filterLessons()
		}
	}
}
</script>

<style lang="sass" scoped>
.border-bottom
	position: absolute
	bottom: 0
	width: 100%
	height: 0
	border-bottom: 1px solid rgba(0, 0, 0, .1)
	
.sidebar
	ion-item
		--padding-start: 15px
		--inner-padding-start: 0

		ion-label
			font-size: 16px
			font-weight: 500
			color: inherit
		
	.order-filter-wrapper
		position: relative
		padding-bottom: 15px

		ion-item
			&.active
				--background: var(--ion-color-primary)
				--border-radius: 4px
				color: white
			
			img
				margin-right: 15px

	.category-wrapper

		ion-item
			border-bottom: 1px solid rgba(0, 0, 0, .1)

		ion-label
			&.active
				color: var(--ion-color-primary)

		ion-icon
			color: #E5E5E5

.main-content
	.top-menu
		margin-top: 27px

@media only screen and (max-width: 850px)
	.top-menu
		margin-top: 12px !important

	.main-content
		h4
			margin-top: 10px
</style>