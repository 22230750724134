<template>
	<ion-page>
		<W-aos-content class="ion-no-padding" ref="content">
			<W-loadable :items="lesson" class="container course-content" skelet="spinner">

				<div>
					<ion-item lines="none" class="menu top">
						<ion-back-button text="" :defaultHref="{name: 'home'}" />

						<div class="d-flex ion-align-items-end height-100">
							<div v-if="lesson?.filter" class="theme-tag">
								<p><span v-for="(filter, index) in lesson.filter" :key="filter.id">{{ filter.name }}<span v-if="index + 1 < Object.keys(lesson.filter).length">, </span></span></p>
							</div>
						</div>

						<div slot="end" class="header-lesson-stats-wrapper d-flex ion-align-items-end height-100">
							<div class="lesson-stats d-flex ion-align-items-center mt-1 mb-1">
								<p class="mt-0 mb-0">{{ lesson.created_at }} •</p>
								<!-- <ion-icon class="size-s" :icon="time"></ion-icon>
								<p class="mt-0 mb-0">	10 min • </p> -->
								<ion-icon class="size-s like clickable" :icon="heart" :color="lesson.liked_by_active_user ? 'danger' : ''" @click="toggleLike()"></ion-icon>
							</div>
						</div>
					</ion-item>
					<div class="menu bottom width-100">
						<div class="d-flex ion-align-items-end height-100 prev-next-wrapper" v-if="lesson?.prev">
							<div class="prev-next-button clickable" @click="$router.push({name: 'lesson', params: {lessonSlug: lesson.prev}})">
								<ion-icon :icon="playSkipBackSharp"></ion-icon>
								<p>{{ $t('Previous')}} {{ $t('lesson')}}</p>
							</div>
						</div>
						<div class="d-flex ion-align-items-end height-100 prev-next-wrapper" v-if="lesson?.next">
							<div class="prev-next-button clickable" @click="$router.push({name: 'lesson', params: {lessonSlug: lesson.next}})">
								<p>{{ $t('Next')}} {{ $t('lesson')}}</p>
								<ion-icon :icon="playSkipForwardSharp"></ion-icon>
							</div>
						</div>

						<!-- <ion-button color="primary" expand="block" class="mt-3">Predchádzajúca lekcia</ion-button> -->
						<!-- <ion-button color="primary" expand="block" class="mt-1">Ďalšia lekcia</ion-button> -->
					</div>

					<div class="mobile-lesson-stats-wrapper container width-100 d-flex ion-justify-content-end mt-2">
						<div class="lesson-stats d-flex ion-align-items-center mt-1 mb-1">
							<p class="mt-0 mb-0">{{ lesson.created_at }} •</p>
							<ion-icon class="size-s" :icon="time"></ion-icon>
							<p class="mt-0 mb-0">	10 min • </p>
							<ion-icon class="size-s like clickable" :icon="heart" :color="lesson.liked_by_active_user ? 'danger' : ''" @click="toggleLike()"></ion-icon>
						</div>
					</div>
						
					<div v-if="lesson.is_locked == '1' && !isLoggedIn" class="video-wrapper container mt-5 mb-5 locked">
						<img v-if="lesson.content_thumbnail" class="thumbnail" :src="lesson.content_thumbnail" alt="">
						<img v-else class="thumbnail" src="../_components/assets/images/thumbnail.png" alt="">
						<div class="locked-wprapper d-flex flex-direction-column ion-justify-content-center ion-align-items-center">
							<img src="../_components/assets/images/locked-lesson-icon.svg" alt="">
							<p>{{$t('Register or log in...')}}</p>
							<ion-button @click="login()">{{$t('Login')}}</ion-button>
						</div>
					</div>

					<div v-else class="width-100">
						<div class="video-wrapper unlocked-video container mt-5 mb-5">
							<div class="video-loader" ref="h5pLoader">
								<ion-spinner name="crescent"></ion-spinner>
							</div>
							<iframe :src="lesson.content_video" 
								width="1088" height="637" frameborder="0" allowfullscreen="allowfullscreen" allow="autoplay *; geolocation *; microphone *; camera *; midi *; encrypted-media *" lang="sk"></iframe>
						</div>

						<div class="lesson-content">
							<h1 class="lesson-name">{{ lesson.name }}</h1>

							<ion-chip v-if="lesson.difficulty == 1" class="green">• {{$t('Beginner')}}</ion-chip>
							<ion-chip v-if="lesson.difficulty == 2" class="orange">• {{$t('Advanced')}}</ion-chip>
							<ion-chip v-if="lesson.difficulty == 3" class="black">• {{$t('Expert')}}</ion-chip>

							<hr>

							<div class="mt-3" v-html="lessonDescription"></div>

							<div class="width-100 d-flex ion-justify-content-end">
								<div class="share-wrapper d-flex flex-direction-column">
									<p>{{$t('Share with others')}}</p>
									<div class="d-flex ion-justify-content-end">
										<img class="clickable" src="./img/facebook.svg" alt="" @click="facebookShare()">
										<div class="vertical-separator"></div>
										<img class="clickable" src="./img/twitter.svg" alt="" @click="twitterShare()">
										<div class="vertical-separator"></div>
										<img class="clickable" src="./img/linkedin.svg" alt="" @click="linkedinShare()">
										<div class="vertical-separator"></div>
										<img class="clickable" src="./img/content_copy.svg" @click="shareLessonLink()" :alt="$t('Copy address')">
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</W-loadable>

			<div v-if="lesson">
				<A-fumbi-recommend />
				<A-fumbi-newsletter class="mb-5" />
			</div>

			<A-fumbi-socials />
			<A-footer />
		</W-aos-content>
	</ion-page>
</template>

<script>
import AFooter from '@/plugins/appfumbi/_layout/footer/a-footer.vue'
import AFumbiRecommend from '../_components/a-fumbi-recommend.vue'
import AFumbiNewsletter from "@/plugins/appfumbi/views/home/_components/a-fumbi-newsletter.vue"
import { toastController } from '@ionic/vue'
import { personCircleOutline, logInOutline, heart, time, playSkipBackSharp, playSkipForwardSharp } from 'ionicons/icons'
import wAxios from '@/plugins/w/axios'
import WLoadable from '@/plugins/w/loadable/w-loadable.vue'
import { mapGetters} from 'vuex'
import AFumbiSocials from "@/plugins/appfumbi/views/home/_components/a-fumbi-socials.vue"

const api = {
	getLesson: (slug) => wAxios.get_auth_data(`/v1/lessons/${slug}`),
	userflag: (lessonId, type, value) =>  wAxios.post_auth_data(`/v1/userflag/lesson/${lessonId}`, {type: type, value: value})
}

export default {
	components: {
		WLoadable,
		AFooter,
		AFumbiRecommend,
		AFumbiNewsletter,
		AFumbiSocials
	},

	data() {
		return {
			personCircleOutline, 
			logInOutline,
			heart,
			time,
			playSkipBackSharp,
			playSkipForwardSharp,

			lesson: null,
			disableLike: false,
		}
	},

	computed: {
		...mapGetters('wAuth', [
			'isLoggedIn',
			'user'
		]),

		lessonDescription() {
			if (!this.lesson.content_description) return ''
			return this.lesson.content_description
		}
	},

	async created() {
		await this._loadData()
		this.eventBus.on('language-changed', this._loadData)

		this._loadVideo()
	},

	beforeUnmount() {
		this.eventBus.off('language-changed', this._loadData)
	},

	methods: {
		async toggleLike() {
			if (!this.isLoggedIn) {
				this.login()
				return
			}

			if (this.disableLike) return
			this.disableLike = true

			const value = this.lesson.liked_by_active_user ? 0 : 1

			try {
				await api.userflag(this.lesson.id, 'like', value)
				this.lesson.liked_by_active_user = value
			} catch (error) {
				this.lesson.like_by_active_user = !value
				this.$wToast.error('An error has occured!')
			}

			this.disableLike = false
		},

		facebookShare() {
			window.location.href = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`
		},

		twitterShare() {
			window.location.href = `https://twitter.com/intent/tweet?text=${encodeURIComponent('Fumbi Academy')}%0A${encodeURIComponent(this.lesson.name)}%0A%0A&url=${encodeURIComponent(window.location.href)}`
		},

		linkedinShare() {
			window.location.href = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(window.location.href)}`
		},

		async shareLessonLink() {
			const currentUrl = window.location.href
			const inputCopy = document.body.appendChild(document.createElement('input'))
			inputCopy.value = currentUrl
			inputCopy.focus()
			inputCopy.select()
			document.execCommand("copy")
			inputCopy.parentNode.removeChild(inputCopy)
			const toast = await toastController
				.create({
					message: this.$t('The link has been copied.'),
					color: "secondary",
					duration: 2000
				})
			return toast.present()
		},

		async _loadData(slug=null) {
			if (this.$route.name != 'lesson') return

			if (!slug || typeof slug != 'string') {
				slug = this.lesson?.slugs
					? this.lesson.slugs[this.$w18n.currentLanguage.value.value]
					: this.$route.params.lessonSlug
			}

			this.lesson = null

			try {
				this.lesson = await api.getLesson(slug)
				this.$router.replace({name: 'lesson', params: {lessonSlug: this.lesson.slug}})
				if (this.lesson.is_fake) this.$router.push({name: 'lessons'})
				this._loadVideo()
				this.$aos.refresh()
			} catch (error) {
				this.lesson = {error: error}
			}
		},

		_loadVideo() {
			setTimeout(()=>{
				this.$refs.h5pLoader.style.display = "none"
			}, 1500)
		},

		login() {
			const redirectParam = `${document.location.origin}/login-successful/${encodeURIComponent(this.$route.path)}`
			const fumbiLoginUrl	= `${process.env.VUE_APP_FUMBI_LOGIN_URL}/externalSignIn/index?redirectUrl=${encodeURIComponent(encodeURI(redirectParam))}&clientId=${process.env.VUE_APP_FUMBI_LOGIN_CLIENT_ID}`
			
			window.location.assign(fumbiLoginUrl)
		}
	},

	watch: {
		'$route.params'() {
			if (this.$route.name == 'lesson') {
				this._loadData(this.$route.params.lessonSlug)
				this._loadVideo()
			}
		}
	}
}
</script>

<style lang="sass" scoped>
.menu
	&.top
		--padding-start: 0
		border-bottom: 1px solid rgba(0, 0, 0, 0.1)

	&.bottom
		display: flex
		justify-content: flex-end

	ion-back-button 
		height: 84px
		margin-right: 100px
		--icon-font-size: 22px
		color: rgba(0, 0, 0, .6)
		margin-left: 15px

	.prev-next-wrapper
		border-top: 2px solid black
		padding: 0 10px

	.prev-next-button
		display: flex
		align-items: center
		background: black
		padding: 0 10px
		border-top: 2px solid black

		ion-icon
			color: white

		p
			color: white
			width: fit-content
			text-align: center
			padding: 5px 10px 
			margin: 0


	.theme-tag
		padding-left: 10px
		border-bottom: 2px solid black
		min-width: 270px

		p
			background: black
			min-width: 100px
			color: white
			width: fit-content
			text-transform: capitalize
			text-align: center
			padding: 6px 30px 4px
			margin: 0 20px 0 0

.mobile-lesson-stats-wrapper
	display: none

.lesson-stats
	color: rgba(0, 0, 0, .6)

	p
		color: inherit
		font-weight: 500

	ion-icon
		margin: 0 5px

		&.like
			color: rgba(102, 102, 102, 0.4)

		&.liked
			color: #FF4B55

.video-wrapper
	height: calc( (100vw / 16 * 9) + 10px )
	max-height: 708px
	border-radius: 20px
	overflow: hidden !important
	background: black
	padding: 0 !important
	position: relative

	.video-loader
		width: 100%
		height: 100%
		position: absolute
		z-index: 999 !important
		background-color: black
		display: flex
		justify-content: center
		align-items: center

		ion-spinner
			--color: white !important
			height: 35px
			width: 35px

	&.locked
		height: calc( (100vw / 16 * 9) - 40px )
		max-height: 672px
		margin-bottom: 0 !important

		.locked-wprapper
			display: flex !important

		.thumbnail
			width: 100%
			height: 100%
			filter: blur(8px)
			-webkit-filter: blur(8px)
			transform: scale(1.05)

	iframe
		width: 100%
		height: 100%
		display: block

	.locked-wprapper
		display: none
		position: absolute
		width: 100%
		height: 100%
		top: 0
		left: 0
		z-index: 999

		p
			color: white
			background: rgba(0, 0, 0, .15)
			border-radius: 10px
			padding: 2px 10px
			margin: 30px 0


		

.lesson-content
	margin: auto
	max-width: 900px

	.lesson-name
		font-size: 35px
		margin: 70px 0 20px
		line-height: 40px

	ion-chip
		font-size: 14px
		font-weight: 400
		padding: 5px 10px 3px
		margin: 0 0 20px

.lesson-content:deep()
	*
		line-height: 28px
		letter-spacing: 0.004em

	img
		width: 100%
		max-height:550px
		border-radius: 20px
		margin: 30px 0 50px

	h1, h2
		font-size: 34px
		line-height: 26px
		letter-spacing: 0.004em
		margin: 80px 0 50px

	.share-wrapper 
		margin-top: 100px

		img
			width: 25px
			height: 25px
			margin: 5px 2px
			border-radius: 0

		div
			height: 100% !important


@media only screen and (max-width: 899px)
	.menu.bottom
		justify-content: space-between
		
		.prev-next-wrapper
			border-top: none
			padding: 0
			margin: 10px 0

@media only screen and (max-width: 739px)
	ion-back-button 
		margin-right: 30px !important

@media only screen and (max-width: 629px)
	ion-back-button 
		height: 30px !important

	.theme-tag
		padding-left: 15px !important

	.header-lesson-stats-wrapper
		display: none

	.mobile-lesson-stats-wrapper
		display: flex

	.lesson-name
		font-size: 30px !important
		margin: 20px 0 !important

	.video-wrapper
		margin-top: 0px !important
		border-radius: 10px
		margin-bottom: 0 !important

		&.locked
			height: calc( (100vw / 16 * 9) + 20px)

	.locked-wprapper

		img
			width: 60px
			height: 60px

		p
			margin: 5px 0 !important
			font-size: 13px

		ion-button
			height: 35px
			font-size: 14px

	.share-wrapper 
		margin-top: 40px !important

@media only screen and (max-width: 449px)
	.menu.bottom
		display: block
		
		.prev-next-wrapper
			border-top: none
			padding: 0
			margin: 10px 0

			.prev-next-button
				width: 100%
				justify-content: center

</style>