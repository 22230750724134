<template>
	<ion-footer class="footer py-5">
		<div class="container">
			<ion-row>

				<ion-col size="6" size-lg="3">
					<h4>{{$t('Products')}}</h4>
					<a target="_blank" :href="`https://fumbi.network/${$w18n.currentLanguage.value.value}/${$t('footer-fumbi-index-portfolio')}`">{{$t('Fumbi Index Portfolio')}}</a>
					<a target="_blank" :href="`https://fumbi.network/${$w18n.currentLanguage.value.value}/${$t('footer-fumbi-advanced-portfolio')}`">{{$t('Fumbi Advanced Portfolio')}}</a>
					<a target="_blank" :href="`https://fumbi.network/${$w18n.currentLanguage.value.value}/${$t('footer-fumbi-staking-portfolio')}`">{{$t('Fumbi Staking Portfolio')}}</a>
					<a target="_blank" :href="`https://fumbi.network/${$w18n.currentLanguage.value.value}/${$t('footer-fumbi-customs')}`">{{$t('Fumbi Custom')}}</a>
					<a target="_blank" :href="`https://fumbi.network/${$w18n.currentLanguage.value.value}/${$t('footer-fumbi-bitcoin-and-gold')}`">{{$t('Fumbi Bitcoin and Gold')}}</a>
					<a target="_blank" :href="`https://fumbi.network/${$w18n.currentLanguage.value.value}/${$t('footer-fumbi-gift-vouchers')}`">{{$t('Gift Vouchers')}}</a>
					<a target="_blank" :href="`https://fumbi.network/${$w18n.currentLanguage.value.value}/${$t('footer-fumbi-custody')}`">{{$t('Fumbi Custody')}}</a>
					<a target="_blank" :href="`https://fumbi.network/${$w18n.currentLanguage.value.value}/${$t('footer-fees')}`">{{$t('Fees')}}</a>
				</ion-col>

				<ion-col size="6" size-lg="3">
					<h4>{{$t('About us')}}</h4>
					<a target="_blank" :href="`https://fumbi.network/${$w18n.currentLanguage.value.value}/${$t('footer-cryptocurrencies')}`">{{$t('Cryptocurrencies')}}</a>
					<a target="_blank" :href="`https://fumbi.network/${$w18n.currentLanguage.value.value}/${$t('footer-vision')}`">{{$t('Vision')}}</a>
					<a target="_blank" :href="`https://fumbi.network/${$w18n.currentLanguage.value.value}/${$t('footer-performance')}`">{{$t('Performance')}}</a>
					<a target="_blank" :href="`https://fumbi.network/${$w18n.currentLanguage.value.value}/${$t('footer-benefits')}`">{{$t('Benefits')}}</a>
					<a target="_blank" :href="`https://fumbi.network/${$w18n.currentLanguage.value.value}/${$t('footer-security')}`">{{$t('Security')}}</a>
					<a target="_blank" :href="`https://fumbi.network/${$w18n.currentLanguage.value.value}/${$t('footer-dictionary')}`">{{$t('Dictionary')}}</a>
					<a target="_blank" :href="`https://fumbi.network/${$w18n.currentLanguage.value.value}/${$t('footer-team')}`">{{$t('Team')}}</a>
				</ion-col>

				<ion-col size="12" size-sm="6" size-lg="3">
					<h4>{{$t('Information')}}</h4>
					<a target="_blank" :href="`https://fumbi.network/${$w18n.currentLanguage.value.value}/${$t('footer-contact')}`">{{$t('Contact us')}}</a>
					<a target="_blank" :href="`https://fumbi.network/${$w18n.currentLanguage.value.value}/${$t('footer-documents')}`">{{$t('Documents')}}</a>
					<a target="_blank" :href="`https://fumbi.network/${$w18n.currentLanguage.value.value}/${$t('footer-warning')}`">{{$t('WARNING')}}</a>
					<a target="_blank" :href="`https://fumbi.network/${$w18n.currentLanguage.value.value}/${$t('footer-payment-methods')}`">{{$t('Payment Methods')}}</a>
					<a target="_blank" :href="`https://fumbi.network/${$w18n.currentLanguage.value.value}/${$t('footer-status')}`">{{$t('Status')}}</a>
					<a href="javascript: Cookiebot.renew()">{{$t('Privacy settings')}}</a>
				</ion-col>

				<ion-col class="text-right-lg" size="12" size-sm="6" size-lg="3">
					<h4>{{$t('Where to find us')}}</h4>
					<p>{{$t('Fumbi Network j.s.a.')}}</p>
					<p>{{$t('Mlynské nivy 5')}}</p>
					<p>{{$t('821 09 Bratislava')}}</p>
					<p>{{$t('Slovakia')}}</p>
					<br>
					<p>{{$t('ID: 52005895')}}</p>

					<ion-row class="socials">
						<a href="https://www.facebook.com/fumbi.networkSK" target="_blank">
							<img class="ml-0" src="./assets/img/facebook.svg" alt="">
						</a>
						<a href="https://twitter.com/FumbiNetwork" target="_blank">
							<img src="./assets/img/twitter.svg" alt="">
						</a>
						<a href="https://www.linkedin.com/company/fumbi/" target="_blank">
							<img src="./assets/img/linkedin.svg" alt="">
						</a>
						<a href="https://www.youtube.com/channel/UC6IUmrMi0OxhwLiMnpkK7Dw" target="_blank">
							<img src="./assets/img/youtube.svg" alt="">
						</a>
						<a href="https://www.instagram.com/fumbi.network/" target="_blank">
							<img class="mr-0" src="./assets/img/instagram.svg" alt="">
						</a>
					</ion-row>
				</ion-col>

			</ion-row>

			<hr>

			<h4>{{$t('WARNING')}}.</h4>

			<br>
			
			<p>{{$t('The cryptoasset market is volatile and the value of cryptoassets can therefore rapidly increase or drop. For this reason, investing in cryptoassets is currently considered risky. Slovak company FUMBI NETWORK j. s. a., has the relevant permits for the provision of services related to cryptoassets in accordance with the legislation of the Slovak Republic.')}} <a class="warning-link" :href="`http://www.nbs.sk/sk/dohlad-nad-financnym-trhom-prakticke-informacie/upozornenia-a-oznamenia/informacia-narodnej-banky-slovenska-k-regulacii-podnikania-v-oblasti-kryptomien`" target="_blank">{{$t('The National Bank of Slovakia does not currently supervise the provision of services related to cryptoassets.')}}</a></p>
			
			<br>
			
			<p>{{$t('In connection with the risky profile of cryptoassets, it is recommended to invest only a part of the free funds that you have reserved for investment purposes and which you are willing to lose in the event of a decline. We do not recommend investing funds that are on loan or that could otherwise affect your life situation. FUMBI NETWORK j. s. a., nor anyone in the cryptoasset market can guarantee a certain return or reliably assume the direction of the cryptoasset market.')}}</p>
			
			<hr>
			
			<div class="copyright d-flex ion-align-items-center">
				<img class="footer-logo" src="./assets/img/fumbi-footer-logo.svg" alt="">
				<p class="disclaimer">© {{ moment().year() }} Fumbi Network j. s. a. All rights reserved.</p>
			</div>
		
		</div>
	</ion-footer>
</template>

<script>
import { logoFacebook, logoTwitter, logoLinkedin } from 'ionicons/icons'
import moment from 'moment'

export default {
	data() {
		return {
			logoFacebook,
			logoTwitter,
			logoLinkedin,
			moment
		}
	},
}
</script>

<style lang="sass" scoped>
.footer
	background: black

	.footer-logo
		height: 45px
		margin-right: 1.5rem

	.socials
		margin-top: 48px

		img
			width: 32px
			display: block
			margin: 0 8px
			transition: transform 0.3s

			&:hover
				transform: scale(1.1)
			
			&.mr-0
				margin-right: 0 !important
			&.ml-0
				margin-left: 0 !important

	ion-col
		margin-bottom: 50px

h4
	color: white
	font-size: 20px
	font-weight: 600
	padding: 8px 0
	margin: 0 0 16px


a,p
	color: white
	font-size: 	14px
	padding: 8px 0
	margin: 0
	font-weight: 600

a
	text-decoration: none
	display: block
	transition: color .3s

	&:hover,
	&:hover ion-icon
		color: var(--ion-color-primary)

	&.warning-link
		display: inline-block
		color: var(--ion-color-primary)
		margin: 0
		padding: 0

hr
	background-color: rgba(255, 255, 255, .3)
	margin: 30px 0

.disclaimer
	margin: 0

@media (max-width: 767px)
	.copyright
		flex-direction: column
		align-items: flex-start !important

@media (max-width: 991px)
	.container
		max-width: 720px

	a,p
		padding: 5px 0

@media (min-width: 992px)
	.text-right-lg
		text-align: right

	.socials
		justify-content: flex-end
	
	ion-col
		margin-bottom: 0 !important

</style>