<template>
	<div class="relative" :style="`margin-top: ${marginTop}px`"  @mouseleave="_closeMenu()">
		<div class="upper-wrapper hidden-sm" @mouseover="_closeMenu()">
			<div class="container d-flex ion-justify-content-between ion-align-items-center height-100">
				<div class="d-flex">
					<!-- <p class="small light pl-0" @click="openExternalLink(`https://fumbi.network/${$w18n.currentLanguage.value.value}`)">Fumbi Network</p> -->
					<p class="small light pl-0" @click="openExternalLink(`https://fumbi.network/${$w18n.currentLanguage.value.value}`)">{{$t('Fumbi Individual')}}</p>
					<p class="small light" @click="openExternalLink(`https://fumbi.network/${$w18n.currentLanguage.value.value}/biznis`)">{{$t('Fumbi Biznis')}}</p>
					<p class="small light is-active" @click="openExternalLink(`https://academy.fumbi.network/`)">{{$t('Fumbi Academy')}}</p>
				</div>

				<div class="d-flex ion-align-items-center">
					<!-- <p class="small" @click="openExternalLink(`https://fumbi.network/${$w18n.currentLanguage.value.value}/contact`)">{{$t('Contact us')}}</p> -->
					<p class="small border-right" @click="openExternalLink(`https://fumbi.network/${$w18n.currentLanguage.value.value}/contact`)">{{$t('Contact us')}}</p>
					<div class="language d-flex ion-align-items-center ml-1" @click="openPopover">
						<span :class="`flag ${$w18n.currentLanguage.value.flag}`" :style="{ backgroundImage: `url('${flag}')` }"></span>{{$w18n.currentLanguage.value.title}}
						<ion-icon class="ml-1" :icon="caretDown"></ion-icon>
					</div>
				</div>
			</div>
		</div>

		<div class="lower-wrapper relative"  @mouseover="_closeMenu()">
			<div class="container d-flex ion-align-items-center height-100">

				<img class="logo" src="./img/logo.svg" alt="logo" @click="$router.push({name: 'home'})">

				<div class="menu-wrapper d-flex height-100" ref="menuWrapper">

					<div class="dropdown-indicator" ref="dropdownIndicator"></div>

					<!-- <div class="dropdown-button d-flex ion-justify-content-center ion-align-items-center" :class="{'is-active': $route.name === 'lessons', 'openDropdown' : openLessons}" @click="$router.push({name: 'lessons'})" @mouseover.stop="lessonsLinkHovered()" ref="lessonsButton">
						<p class="fw-bold d-flex ion-align-items-center" >{{$t('Lessons')}}</p>
						<ion-icon class="ml-1" :icon="caretDown"></ion-icon>
					</div> -->

					<div class="dropdown-button d-flex ion-justify-content-center ion-align-items-center" :class="{'is-active': $route.name === 'lessons'}" @click="$router.push({name: 'lessons'})" @mouseover.stop="lessonsLinkHovered()" ref="lessonsButton">
						<p class="fw-bold d-flex ion-align-items-center" >{{$t('Lessons')}}</p>
					</div>

					<div v-if="isLoggedIn" class="dropdown-button d-flex ion-justify-content-center ion-align-items-center" :class="{'is-active': $route.name === 'profile', 'openDropdown' : openProfile}" @click="$router.push({name: 'profile'})" @mouseover.stop="profileLinkHovered()" ref="profileButton">
						<p class="fw-bold d-flex ion-align-items-center" >{{$t('Profile')}}</p>
						<ion-icon class="ml-1" :icon="caretDown"></ion-icon>
					</div>

					<div class="dropdown-button d-flex ion-justify-content-center ion-align-items-center" @click="openExternalLink(`https://fumbi.network/${$w18n.currentLanguage.value.value}/${$t('help')}/${$t('general-questions')}/`)" @mouseover.stop="supportLinkHovered()" ref="supportButton">
						<p class="fw-bold d-flex ion-align-items-center" >{{$t('Support')}}</p>
					</div>

				</div>

				<div v-if="!isLoggedIn" class="buttons d-flex ion-justify-content-between ion-align-items-center">
					<p class="fw-bold" @click="register()">{{$t('Registration')}}</p>
					<ion-button :class="{'blue-button' : isHomePage}" @click="login()">{{$t('Login')}}</ion-button>
				</div>

				<div v-if="isLoggedIn" class="buttons d-flex ion-justify-content-between ion-align-items-center">
					<ion-button :class="{'blue-button' : isHomePage}" @click="logout()">{{$t('Log out')}}</ion-button>
				</div>
			</div>
		</div>

		<div v-if="openLessons || openProfile" class="submenu">
			<div class="container">
				<ion-row class="margin" :class="{'lessons-margin': openLessons}">
					<ion-col size="5">
						<p class="fw-bold color-primary mb-2">{{$t('Categories')}}</p>
						<div v-if="openProfile">
							<p class="link" @click="$router.push({name: 'profile', params: {profileTab: 'user-form'}}); openLessons = false; openProfile = false" 
								:class="{'is-active': $route.name === 'profile' && (!$route.params.profileTab || $route.params.profileTab === 'user-form')}">{{$t('Particulars')}}</p>
							<p class="link" @click="$router.push({name: 'profile', params: {profileTab: 'user-themes'}}); openLessons = false; openProfile = false" 
								:class="{'is-active': $route.name === 'profile' && $route.params.profileTab === 'user-themes'}">{{$t('My lessons')}}</p>
							<p class="link" @click="$router.push({name: 'profile', params: {profileTab: 'user-liked'}}); openLessons = false; openProfile = false" 
								:class="{'is-active': $route.name === 'profile' && $route.params.profileTab === 'user-liked'}">{{$t('Liked')}}</p>
						</div>
						<div v-if="openLessons">
							<p class="link" v-for="category in categories" :key="category.id" @click="$router.replace({name: 'lessons', params: {categoryTab: category.slug}}); openLessons = false; openProfile = false" 
								:class="{'is-active': $route.name === 'lessons' && $route.params.categoryTab === category.slug}">{{category.name}}</p>
						</div>
					</ion-col>
					<ion-col v-if="openLessons" size="7">
						<p class="fw-bold color-primary">{{$t('The latest course')}}</p>
						<W-loadable :items="lessons" skelet="spinner">
							<Z-lesson-card class="mt-2 mb-3" v-for="lesson in lessons" :key="lesson.id" :lesson="lesson" :grid="false" />
						</W-loadable>
						<ion-button color="primary" @click="$router.push({name: 'lessons'})">{{$t('All themes')}}</ion-button>
					</ion-col>
				</ion-row>
			</div>
		</div>
	</div>
</template>

<script>
import { caretDown, caretUp } 	from 'ionicons/icons'
import { mapGetters} 			from 'vuex'
import { popoverController } 	from '@ionic/vue'
import wAxios 					from '@/plugins/w/axios'
import APopover 				from '@/plugins/w/w18n/components/a-language-popover.vue'
import flag 					from '@/plugins/w/w18n/components/img/flags.png'
import ZLessonCard 				from '@/plugins/lib@lesson/_components/z-lesson-card.vue'

const api = {
	getLessons: () => wAxios.get_auth_data(`v1/lessons?result_per_page=1`),
	getCategories: () => wAxios.get_auth_data('/v1/filters')
}

export default {
	components: {
		ZLessonCard,
	},

	data() {
		return {
			caretDown,
			caretUp,
			openLessons: false,
			openProfile: false,
			openMenu: false,
			isHomePage: false,
			flag,
			lessons: [],
			categories: [],
			marginTop: 0,
			loginRedirectLanguages: {
				'sk': 'sk',
				'cs': 'cs_CZ',
			}
		}
	},
	
	computed: {
		...mapGetters('wAuth', [
			'user',
			'isLoggedIn'
		])
	},

	async created() {
		if (this.$route.path == "/") this.isHomePage = true

		this._loadData()

		this.eventBus.on('language-changed', this._loadData)

		this.eventBus.on('ion-scroll', (e) => {
			this.marginTop = e.detail.scrollTop < 50
				? - (e.detail.scrollTop)
				: - 50
		})
	},

	beforeUnmount() {
		this.eventBus.off('language-changed', this._loadData)
	},

	methods: {	
		async _loadData() {
			try {
				this.lessons = await api.getLessons()
				this.categories = await api.getCategories()
			} catch(error) {
				this.lessons = error
			}
		},

		openExternalLink(link) {
			window.open(link)
		},

		login() {
			const redirectParam = `${document.location.origin}/login-successful/${encodeURIComponent('/lessons')}`
			const fumbiLoginUrl	= `${process.env.VUE_APP_FUMBI_LOGIN_URL}/externalSignIn/index?lang=${this.getloginRedirectLanguage()}&redirectUrl=${encodeURIComponent(encodeURI(redirectParam))}&clientId=${process.env.VUE_APP_FUMBI_LOGIN_CLIENT_ID}`

			
			window.location.assign(fumbiLoginUrl)
		},

		register() {
			window.location.assign(`https://my.fumbi.network/join/index?lang=${this.getloginRedirectLanguage()}&page=ACADEMY`)
		},

		getloginRedirectLanguage() {
			if (this.loginRedirectLanguages[this.$w18n.currentLanguage.value.value]) return this.loginRedirectLanguages[this.$w18n.currentLanguage.value.value]
			return 'sk'
		},

		async logout() {
			await this.$store.dispatch('wAuth/logout')
			window.open(location.origin, "_self")
		},

		async openPopover(ev) {
			const popover = await popoverController
				.create({
					component: APopover,
					componentProps: { 
						languages: this.$w18n.availableLanguages
					},
					event: ev,
					translucent: true
				})

			await popover.present()
		},

		lessonsLinkHovered() {
			// if (this.openLessons) return

			this.setMenuIndicatorPosition('lessonsButton')
			this.openLessons 	= false
			this.openProfile 	= false
			this.openMenu		= false
		},

		profileLinkHovered() {
			if (this.openProfile) return

			this.setMenuIndicatorPosition('profileButton')
			this.openLessons 	= false
			this.openProfile 	= true
			this.openMenu		= true
		},

		supportLinkHovered() {
			this.setMenuIndicatorPosition('supportButton')
			this.openLessons 	= false
			this.openProfile 	= false
			this.openMenu		= false
		},

		setMenuIndicatorPosition(button) {
			// console.log(this.$refs[button].getBoundingClientRect())
			const buttonPosition = (this.$refs[button].getBoundingClientRect().left - this.$refs['menuWrapper'].getBoundingClientRect().left).toFixed()

			this.$refs['dropdownIndicator'].style.left = `${buttonPosition}px`
			this.$refs['dropdownIndicator'].style.width = `${this.$refs[button].getBoundingClientRect().width.toFixed()}px`
		},

		hideMenuIndicator() {
			this.$refs['dropdownIndicator'].style.left = '0px'
			this.$refs['dropdownIndicator'].style.width = '0px'
		},

		_closeMenu() {
			this.hideMenuIndicator()
			this.openLessons 	= false
			this.openProfile 	= false
			this.openMenu		= false
		},
	},

	watch: {
		'$route'() {
			if (this.$route.path == "/") this.isHomePage = true
			else this.isHomePage = false
		}
	}
}
</script>

<style lang="sass" scoped>
ion-button
	--background: #94C93D
	--background-hover: #9fce50
	--background-focused: #9fce50
	--background-activated: #82b136
		
	// --ion-color-secondary: #94C93D
	// --ion-color-secondary-rgb: 148,201,61
	// --ion-color-secondary-contrast: #ffffff
	// --ion-color-secondary-contrast-rgb: 255,255,255
	// --ion-color-secondary-shade: #82b136
	// --ion-color-secondary-tint: #9fce50

.blue-button
	--background: #398ce0 !important
	--background-hover: #4d98e3 !important
	--background-focused: #4d98e3 !important
	--background-activated: #327bc5 !important

.upper-wrapper
	height: 50px
	border-bottom: 1px solid rgba(0, 0, 0, .1)
	padding: 0 20px
	background-color: #2C2E30

	p
		padding: 0 15px
		margin: 0
		color: #ffffff
		cursor: pointer
		transition: .15s

		&.light
			color: rgba(#ffffff, .6)
		&:hover,
		&.is-active
			color: white
	
	ion-icon
		color: #565859

	.language
		color: #fff

		&:hover
			cursor: pointer

	.flag
		width: 52px
		height: 39px
		transform: scale(0.4)

		&.sk
			background-position: 0 -8569px

		&.en
			background-position: 0 -3444px

		&.cz
			background-position: 0 -2337px

		&.pl
			background-position: 0 -7626px

.lower-wrapper
	height: 100px

	.container
		border-bottom: 1px solid rgba(0, 0, 0, .1)

	.menu
		height: 19px
		margin-top: 3px

	.logo
		height: 45px
		margin-right: 100px
		margin-top: -7px
		&:hover
			cursor: pointer

	p
		margin: 0
		padding: 0 15px
		cursor: pointer

	.menu-wrapper
		position: relative

	.dropdown-indicator
		position: absolute
		bottom: 0
		left: 0
		height: 5px
		width: 0
		background: var(--ion-color-primary)
		transition: .3s

	.dropdown-button
		height: 100%
		width: fit-content
		padding: 0 15px
		cursor: pointer
		z-index: 10

		p
			padding: 0
			width: fit-content
			color: inherit
			transition: .3s

		ion-icon
			color: inherit
			transform: rotate(90deg)
			transition: .3s

		&:hover
			color: var(--ion-color-primary)

		&.is-active
			color: var(--ion-color-primary)

		&.openDropdown
			color: var(--ion-color-primary)
			ion-icon
				transform: rotate(0deg)

	.buttons
		margin: 0 0 0 auto

	.user
		width: 25px
		height: 25px

.border-right
	border-right: 1px solid rgba(255, 255, 255, .2)

.submenu
	position: absolute
	top: 149px
	left: 0
	right: 0
	display: block
	background-color: #fff
	z-index: 1
	box-shadow: 0px 235px 235px rgba(0, 0, 0, 0.17)
	animation: dropdownEntrance 300ms ease 0s 1 normal forwards

	.container
		padding: 35px 15px 20px
		border-top: 1px solid rgba(0, 0, 0, .1)

	.margin	
		margin-left: 240px

	.link
		cursor: pointer
		&.is-active,
		&:hover
			color: var(--ion-color-primary)

.banner
	padding: 20px 30px
	border-radius: 9px

	.color-white
		color: #fff

.dark-blue
	background: #11253E

	p:first-child
		color: #008CE7

.light-blue
	background: #008CE7

	p:first-child
		color: #11253E


@keyframes dropdownEntrance
	0%
		opacity: 0
	100% 
		opacity: 1

@media only screen and (max-width: 1150px)
	.submenu
		.lessons-margin	
			margin-left: 40px !important
</style>