export default {
	filterLessons(searchQuery, lessons) {
		let filteredLessons 		= lessons
		let normalizedSearchQuery 	= searchQuery.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, "")

		if (searchQuery.length > 2) {
			filteredLessons = filteredLessons.filter(lesson => {
				const normalizedDesc = lesson.name.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, "")
				return normalizedDesc.includes(normalizedSearchQuery)
			})
		}
		
		return filteredLessons
	},
}