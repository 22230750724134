<template>
	<ion-card @click="goToLesson()" 
		:class="{'list': !grid && !gridFixed, 'locked': !isLoggedIn && cardLesson.is_locked == '1' && !cardLesson.is_fake, 'fake-lesson' : cardLesson.is_fake }">
		<ion-card-header class="theme-img-wrapper ion-no-padding d-flex ion-align-items-center ion-justify-content-center">
			<img class="thumbnail" v-if="cardLesson.content_thumbnail" :src="cardLesson.content_thumbnail">
			<!-- <div v-if="cardLesson.filter?.name" class="theme-tag">
				<p>{{ cardLesson.filter.name }}</p>
			</div> -->
			
			<div class="locked-wprapper d-flex flex-direction-column ion-justify-content-center ion-align-items-center">
				<img src="./assets/images/locked-lesson-icon.svg" alt="">
				<p class="mt-1">{{$t('Register or log in...')}}</p>
			</div>

			<div class="fake-wprapper d-flex flex-direction-column ion-justify-content-center ion-align-items-center">
				<img src="./assets/images/locked-lesson-icon.svg" alt="">
				<p class="mt-1">{{$t('Coming soon!')}}</p>
			</div>

			<div v-if="lessonProgress && lesson.finished_by_active_user" class="filter-tag d-flex ion-align-items-center">
				<p class="small mb-0">{{$t('Finished')}}</p><img class="ml-1" src="@/plugins/lib@profile/img/finished_white.svg" alt="finished">
			</div>
			<div v-if="lessonProgress && !lesson.finished_by_active_user" class="filter-tag d-flex ion-align-items-center">
				<p class="small mb-0">{{$t('Started')}}</p><img class="ml-1" src="@/plugins/lib@profile/img/started_white.svg" alt="started">
			</div>
		</ion-card-header>

		<div>
			<div class="lesson-stats d-flex ion-align-items-center mt-1 mb-1">
				<p class="mt-0 mb-0">{{ cardLesson.created_at }} •</p>
				<!-- <ion-icon class="size-s" :icon="time"></ion-icon> -->
				<!-- <p class="mt-0 mb-0">	10 min • </p> -->
				<ion-icon class="size-s like" :icon="heart" :color="cardLesson.liked_by_active_user ? 'danger' : ''" @click.stop="toggleLike()"></ion-icon>
			</div>

			<ion-card-title class="mb-2">{{ cardLesson.name }}</ion-card-title>

			<ion-chip v-if="cardLesson.difficulty == 1" class="green">• {{$t('Beginner')}}</ion-chip>
			<!-- <ion-chip v-if="cardLesson.difficulty == 2" class="yellow">• {{$t('intermediate')}}</ion-chip> -->
			<ion-chip v-if="cardLesson.difficulty == 2" class="orange">• {{$t('Advanced')}}</ion-chip>
			<!-- <ion-chip v-if="cardLesson.difficulty == 4" class="red">• {{$t('More advanced')}}</ion-chip> -->
			<ion-chip v-if="cardLesson.difficulty == 3" class="black">• {{$t('Expert')}}</ion-chip>
		</div>
	</ion-card>
</template>

<script>
import { heart, time } from 'ionicons/icons'
import { toastController } from '@ionic/vue'
import wAxios from '@/plugins/w/axios'
import { mapGetters} from 'vuex'

const api = {
	userflag: (lessonId, type, value) =>  wAxios.post_auth_data(`/v1/userflag/lesson/${lessonId}`, {type: type, value: value})
}

export default {
	props: {
		lesson: {
			type: Object,
			required: true
		},
		lessonProgress: {
			type: Boolean,
			required: false,
			default: false
		},
		grid: {
			type: Boolean,
			required: true
		},
	},

	computed: {
		...mapGetters('wAuth', [
			'isLoggedIn',
			'user'
		]),
	},

	data() {
		return {
			heart, 
			time,
			cardLesson: this.lesson,
			disableLike: false,
			gridFixed: false,
		}
	},

	created() {
		if (document.documentElement.clientWidth <= 575) this.gridFixed = true
	},

	mounted() {
		window.addEventListener('resize', () => {
			if (document.documentElement.clientWidth <= 575) this.gridFixed = true
			else this.gridFixed = false
		})
	},

	methods: {
		goToLesson() {
			if (this.cardLesson.is_fake) return
			this.$router.push({name: 'lesson', params: {lessonSlug: this.cardLesson.slug}})
		},

		async toggleLike() {
			if (this.disableLike) return
			this.disableLike = true

			const value = this.cardLesson.liked_by_active_user ? 0 : 1

			try {
				await api.userflag(this.cardLesson.id, 'like', value)
				this.cardLesson.liked_by_active_user = value
			} catch (error) {
				this.cardLesson.like_by_active_user = !value
				console.log('error')
				const toast = await toastController
					.create({
						message: 'Vyskytla sa chyba!',
						color: "danger",
						duration: 2000
					})
				this.disableLike = false
				return toast.present()
			}

			this.disableLike = false
		},
	}
}
</script>