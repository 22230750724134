<template>
	<W-aos-content>
		<div class="ion-text-center pt-7">
			<h2>Login is required</h2>

			<ion-button @click="redirectToLogin()">Sign in</ion-button>
			<ion-button @click="$router.go(-1)" fill="outline">Go back</ion-button>
		</div>
	</W-aos-content>
</template>

<script>
export default {
	methods: {
		redirectToLogin() {
			const redirectParam = `${document.location.origin}/login-successful/${this.$route.params.redirectToUrl}`
			const fumbiLoginUrl	= `${process.env.VUE_APP_FUMBI_LOGIN_URL}/externalSignIn/index?redirectUrl=${encodeURIComponent(redirectParam)}&clientId=${process.env.VUE_APP_FUMBI_LOGIN_CLIENT_ID}`
			
			window.location.assign(fumbiLoginUrl)
		}
	}
}
</script>