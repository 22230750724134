<template>
	<ion-page>
		<ion-header>
			<ion-toolbar>
				<ion-title>{{$t('Profile')}}</ion-title>
				<ion-icon class="size-l mr-1" slot="end" :icon="close" @click="closeModal()"></ion-icon>
			</ion-toolbar>
		</ion-header>

		<ion-content class="ion-padding">
			<div class="content-wrapper d-flex flex-direction-column ion-justify-content-between">

				<div class="width-100">
					<div class="order-filter width-100 pb-1">

						<ion-item lines="none" pointer class="mt-1" @click="filterActiveTab = 'user-form'" :class="{'active': filterActiveTab === 'user-form'}">
							<img src="../../img/user.svg" alt="">
							<ion-label>{{$t('Particulars')}}</ion-label>
						</ion-item>

						<ion-item lines="none" pointer @click="filterActiveTab = 'user-themes'" :class="{'active': filterActiveTab === 'user-themes'}">
							<img src="../../img/lessons.svg" alt="">
							<ion-label>{{$t('My lessons')}}</ion-label>
						</ion-item>

						<ion-item lines="none" pointer @click="filterActiveTab = 'user-liked'" :class="{'active': filterActiveTab === 'user-liked'}">
							<img src="../../img/heart.svg" alt="">
							<ion-label>{{$t('Popular')}}</ion-label>
						</ion-item>

						<!-- Theme filter -->
						<div class="border-bottom hidden" :class="{'show' : filterActiveTab === 'user-themes'}"></div>

						<div class="width-100 hidden" :class="{'show' : filterActiveTab === 'user-themes'}">
							<ion-item lines="none" pointer @click="filterFilter = 'all'" class="mt-2" :class="{'active': filterFilter === 'all'}">
								<img src="../../img/all.svg" alt="">
								<ion-label>{{$t('All')}}</ion-label>
							</ion-item>

							<ion-item lines="none" pointer @click="filterFilter = 'finished'" :class="{'active': filterFilter === 'finished'}">
								<img src="../../img/finished.svg" alt="">
								<ion-label>{{$t('Finished')}}</ion-label>
							</ion-item>

							<ion-item lines="none" pointer @click="filterFilter = 'started'" :class="{'active': filterFilter === 'started'}">
								<img src="../../img/started.svg" alt="">
								<ion-label>{{$t('Started')}}</ion-label>
							</ion-item>
						</div>
					</div>
				</div>

				<div class="d-flex ion-justify-content-center">
					<ion-button expand="block" color="primary" fill="solid"		@click="saveModal()">Uožiť</ion-button>
					<ion-button expand="block" color="primary" fill="outline" 	@click="closeModal()">Zrušit</ion-button>
				</div>

			</div>

		</ion-content>
	</ion-page>
</template>

<script>
import { close, closeOutline, checkmark } 	from 'ionicons/icons'
import { modalController } 					from '@ionic/vue'

export default {
	props: {
		activeTab: {
			type: String,
			required: true
		},

		filter: {
			type: String,
			required: true
		},
	},

	data() {
		return {
			close,
			closeOutline,
			checkmark,
			filterActiveTab: 'user-form',
			filterFilter: 'all',
		}
	},

	created() {
		this.filterActiveTab 	= this.activeTab
		this.filterFilter 		= this.filter
	},

	methods: {
		closeModal() {
			modalController.dismiss()
		},

		saveModal() {
			modalController.dismiss({
				activeTab: this.filterActiveTab,
				filter: this.filterFilter,
			})
		},
	}
}
</script>

<style lang="sass" scoped>
.hidden
	display: none

.show
	display: block !important

.border-bottom
	margin: 7px 0
	width: 100%
	height: 0
	border-bottom: 1px solid rgba(0, 0, 0, .1)

ion-header
	ion-toolbar
		--min-height: 50px
		// --background: var(--ion-color-primary)
		// color: white

		ion-title
			font-weight: 500
			// letter-spacing: 1px

ion-button
	margin: 5px
	margin-top: 40px
	height: 35px
	font-size: 14px
	font-weight: 400

.content-wrapper
	min-height: 100%

.order-filter
	ion-label
		font-weight: 500

h4
	font-weight: 500

ion-item
	--padding-start: 0
	--inner-padding-end: 0

	&.active
		--background: var(--ion-color-primary)
		--border-radius: 4px
		color: white

	img
		margin: 0 10px

@media only screen and (max-width: 1000px)
	ion-label
		font-size: 14px !important
</style>