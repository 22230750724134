<template>
	<div>
		<form action="">
			<ion-row>
				<!-- <ion-col size="12" class="ion-no-padding">
					<div class="tabname">
						<p class="fw-bold large color-primary ml-1 mb-0">{{$t('Particulars')}}</p>
					</div>
				</ion-col> -->
				<Z-top-menu :tabName="$t('Particulars')" :showSearchAndView="false" :showSeparateFilter="true" @openModal="$emit('openModal')" />
				
				<ion-col size=12 size-sm="6" size-xl="4">
					<cmf-input v-model="v$.form.full_name" :vBe="vBe" :label="$t('Name and surname')" readonly />
				</ion-col>

				<ion-col size=12 size-sm="6" size-xl="4">
					<cmf-input v-model="v$.form.email" :vBe="vBe" label="Email" readonly />
				</ion-col>

				<!-- <ion-col size=12 size-sm="6" size-xl="4">
					<cmf-input v-model="v$.form.tel" :vBe="vBe" :label="$t('Phone number')" readonly />
				</ion-col>

				<ion-col size=12 size-sm="6" size-xl="4">
					<cmf-input v-model="v$.form.birth_date" :vBe="vBe" :label="$t('Date of birth')" readonly />
				</ion-col>

				<ion-col size=12 size-sm="6" size-xl="4">
					<cmf-input v-model="v$.form.nationality" :vBe="vBe" :label="$t('Nationality')" readonly />
				</ion-col>

				<ion-col size=12 size-sm="6" size-xl="4">
					<cmf-input v-model="v$.form.fumbi_id" :vBe="vBe" label="Fumbi ID" readonly />
				</ion-col>

				<ion-col size="12">
					<p class="fw-bold large color-primary mt-2">{{$t('Address')}}</p>
					<hr>
				</ion-col>

				<ion-col size=12 size-sm="6" size-xl="4">
					<cmf-input v-model="v$.form.full_street_name" :vBe="vBe" :label="$t('Street and number')" readonly />
				</ion-col>

				<ion-col size=12 size-sm="6" size-xl="4">
					<cmf-input v-model="v$.form.town" :vBe="vBe" :label="$t('City')" readonly />
				</ion-col>

				<ion-col size=12 size-sm="6" size-xl="4">
					<cmf-input v-model="v$.form.zipcode" :vBe="vBe" :label="$t('Postcode')" readonly />
				</ion-col>

				<ion-col size=12 size-sm="6" size-xl="4">
					<cmf-input v-model="v$.form.state" :vBe="vBe" :label="$t('State')" readonly />
				</ion-col>


				<ion-col size="12">
					<p class="fw-bold large color-primary mt-2">{{$t('Bank connection')}}</p>
					<hr>
				</ion-col>

				<ion-col size=12 size-sm="6" size-xl="4">
					<cmf-input v-model="v$.form.bank_owner_name" :vBe="vBe" :label="$t(`Cardholder's name`)" readonly />
				</ion-col>

				<ion-col size=12 size-sm="6" size-xl="4">
					<cmf-input v-model="v$.form.bank_card_number" :vBe="vBe" :label="$t('Card number')" readonly />
				</ion-col>

				<ion-col size=12 size-sm="6" size-xl="4">
					<cmf-input v-model="v$.form.bank_expiration_date" :vBe="vBe" :label="$t('Expiration date')" readonly />
				</ion-col>

				<ion-col size=12 size-sm="6" size-xl="4">
					<cmf-input v-model="v$.form.iban" :vBe="vBe" label="IBAN" readonly />
				</ion-col> -->

			</ion-row>

			<ion-button class="mt-4" color="secondary" @click="updateProfile"><img class="mr-1" src="@/plugins/lib@profile/img/edit.svg" alt="edit">{{$t('Edit')}}</ion-button>
		</form>
	</div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { mapGetters} 	from 'vuex'
import ZTopMenu from './z-top-menu.vue'

export default {
	components: {
		ZTopMenu,
	},

	data() {
		return {
			form: {},
			v$: useVuelidate(),
			vBe: [],
		}
	},

	computed: {
		...mapGetters('wAuth', [
			'user'
		]),
	},

	validations() {
		return {
			form: {
				username: { required },
				full_name: { required },
				email: { required, email },
				tel: { required },
				birth_date: { required },
				nationality: { required },
				fumbi_id: { required },
				full_street_name: { required },
				town: { required },
				zipcode: { required },
				state: { required },
				bank_owner_name: { required },
				bank_card_number: { required },
				bank_expiration_date: { required },
				iban: { required }
			}
		}
	},

	created () {
		this.form = this.user
	},

	methods: {
		async updateProfile() {
			window.open(`https://my.fumbi.network/member/index/?lang=${this.$w18n.currentLanguage.value.value}#/personalInformation`)
		}
	},
}
</script>

<style lang="sass" scoped>

h1
	font-size: 24px

.tabname
	height: 43px
	border-bottom: 1px solid rgba(0, 0, 0, .1)
	padding-bottom: 5px

p
	margin-top: 13px

hr
	background-color: #e6e6e6
	margin: 0 auto 15px

:deep(ion-input)
	--padding-start: 0!important
	color: var(--ion-color-dark)

	.native-input[disabled].sc-ion-input-ios
		opacity: 1
		font-weight: 700

@media (max-width: 992px)
	ion-col
		--ion-grid-column-padding: 0 !important

		.cmf
			margin-left: 5px

</style>