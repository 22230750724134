<template>
	<ion-content ref="content" :scroll-events="true" @ionScroll="onScroll" class="w-aos-content">
		<!-- :style="`--padding-top: ${paddingTop}px`"  -->
		<slot></slot>
	</ion-content>
</template>

<script>
export default {
	methods: {
		onScroll(e) {
			this.eventBus.emit('ion-scroll', e)
			this.$aos.onScroll(e.detail.currentY, e.srcElement.clientHeight)

			// this.paddingTop = e.detail.scrollTop < 50
			// 	? e.detail.scrollTop
			// 	: 50
		}
	}

	// data() {
	// 	return {
	// 		paddingTop: 0
	// 	}
	// },
}
</script>