<template>
	<ion-row>
		<Z-top-menu :tabName="$t('Popular')" @openModal="$emit('openModal')" @changeGrid="toggleGrid" @searchQueryChanged="filterLessons" ref="topMenu" />

		<W-loadable class="lessons-wrapper width-100" :items="filteredLessons" skelet="spinner" :messageEmpty="lessons != '' ? $t('Search does not match any lessons!') : $t('There are no lessons in this category')">
			<ion-row class="lessons">
				<ion-col v-for="lesson in filteredLessons" :key="lesson.id" size="12" :size-sm="(!grid && !gridFixed) ? 12 : 6" :size-lg="(!grid && !gridFixed) ? 12 : 4">

					<Z-lesson-card :lesson="lesson" :grid="grid" />

				</ion-col>
			</ion-row>
		</W-loadable>
	</ion-row>
</template>

<script>
import { heart, time, search } from 'ionicons/icons'
import wAxios from '@/plugins/w/axios'
import LessonUtils from '@/plugins/lib@lesson/_utils/lesson.utils'

import ZTopMenu from './z-top-menu.vue'
import ZLessonCard from '@/plugins/lib@lesson/_components/z-lesson-card.vue'


const api = {
	getlessons: () => wAxios.get_auth_data(`v1/userflag/models/lesson/like`),
	userflag: (lessonId, type, value) =>  wAxios.post_auth_data(`/v1/userflag/lesson/${lessonId}`, {type: type, value: value})
}

export default {
	components: {
		ZTopMenu,
		ZLessonCard,
	},

	data() {
		return {
			heart,
			time,
			search, 
			lessons: [],
			filteredLessons: null,
			disableLike: false,
			searchQuery: "",
			grid: true,
			gridFixed: false,
		}
	},

	async created() {
		this._loadData()
		this.eventBus.on('language-changed', this._loadData)
	},

	beforeUnmount() {
		this.eventBus.off('language-changed', this._loadData)
	},

	mounted() {
		window.addEventListener('resize', () => {
			this.gridFixed = document.documentElement.clientWidth <= 575
		})
	},

	methods: {
		openSearchbar() {
			this.$refs.searchbar.$el.setFocus()
		},

		toggleGrid(grid) {
			this.grid = grid
		},

		async _loadData() {
			if (this.$route.name != 'profile') return

			this.filteredLessons = null

			try {
				this.lessons = await api.getlessons()
				this.$aos.refresh()
			} catch (error) {
				this.lessons = {error: error}
			}

			this.filteredLessons = this.lessons
			this.filterLessons(this.searchQuery)
		},

		filterLessons(searchQuery) {
			this.searchQuery 		= searchQuery
			this.filteredLessons 	= LessonUtils.filterLessons(searchQuery, this.lessons)
		},

		async toggleLike(lesson) {
			if (this.disableLike) return
			this.disableLike = true

			const value = lesson.liked_by_active_user ? 0 : 1

			try {
				await api.userflag(lesson.id, 'like', value)
				lesson.liked_by_active_user = value
			} catch (error) {
				lesson.like_by_active_user = !value
				this.$wToast.error(error)
			}

			this.disableLike = false
		},
	}
}
</script>

<style lang="sass" scoped>
.lessons-wrapper:deep(div.ion-padding)
	padding-top: 0 !important
	padding-left: 10px
</style>