// Vue init
import { createApp } from 'vue'
import App from '@/App.vue'
import { IonicVue } from '@ionic/vue'

import router from './router'
import store from '@/store'
import '@/custom.shared'
import '@/plugins/w/devmini/index'

import AOS from '@/plugins/w/aos'

const app = createApp(App)
	.use(IonicVue)
	.use(router)
	.use(store)
	// .use(w18n, w18nOptions)
	.use(AOS, {
		offset: 50,
		delay: 50,
		duration: 450,
		easing: 'ease-in-out',
		mirror: false,
		once: false,
		anchorPlacement: 'bottom',
		disable: 'mobile'
	})


// We had to manually install i18n here to be able to export i18n
import  vendorI18n  from  '@/plugins/w/translate/index'
import { W18n } from '@/plugins/w/w18n/models/w18n'

const w18nOptions = {
	languages: [
		{title: "Slovenčina", flag: "sk", value: "sk"},
		{title: "Čeština", flag: "cz", value: "cs"},
		// {title: "Polski", flag: "pl", value: "pl"},
		// {title: "English", flag: "en", value: "en"}
	],
	defaultLanguage: 'sk'
}

app.use(vendorI18n)	
const w18n = new W18n(w18nOptions)
app.config.globalProperties.$w18n = w18n

export const i18n = w18n





// Add ionic components
import {registerIonicComponents} from '@/plugins/w/ionic'
registerIonicComponents(app)

router.isReady().then(() => {
	app.mount('#app')
})

// App web version
// import { isWeb, isVideoWeb } from '@/plugins/appacademy/_utils/isWeb.util'
// app.config.globalProperties.$isWeb = isWeb()
// app.config.globalProperties.$isVideoWeb = isVideoWeb()
// window.addEventListener('resize', () => {
// 	console.log('resize')
// 	console.log(isWeb())
// 	app.config.globalProperties.$isWeb = isWeb()
// 	app.config.globalProperties.$isVideoWeb = isVideoWeb()
// })

// Themes
import './_theme/index.sass'

// Featureconfig
import wConfig from '@/plugins/w/featureconfig/index'
import defaultConfig from '@/config/default'
import customConfig from './_config/config'

wConfig.addConfigAndFeatureVariables(app, customConfig, defaultConfig)

//Fields
import CmfInput from '@/plugins/cmf/cmf-input/cmf-input.vue'
import CmfSelect from '@/plugins/cmf/cmf-select/cmf-select.vue'
import CmfCheckbox from '@/plugins/cmf/cmf-checkbox/cmf-checkbox.vue'
import CmfDatePicker from '@/plugins/cmf/cmf-date-picker/cmf-date-picker.vue'
import CmfTextarea from '@/plugins/cmf/cmf-textarea/cmf-textarea.vue'
import WLoadable from '@/plugins/w/loadable/w-loadable.vue'

app.component('cmf-input', CmfInput)
app.component('cmf-select', CmfSelect)
app.component('cmf-checkbox', CmfCheckbox)
app.component('cmf-date-picker', CmfDatePicker)
app.component('cmf-textarea', CmfTextarea)
app.component('W-loadable', WLoadable)


// Event emitter
import mitt from 'mitt'
const eventBus = mitt()
app.config.globalProperties.eventBus = eventBus

// Toast
import wToast from '@/plugins/w/toast'
app.config.globalProperties.$wToast = wToast

// Loader
import wLoader from '@/plugins/w/loader'
app.config.globalProperties.$wLoader = wLoader

export default app

// export function getW18n() {
// 	return app.config.globalProperties.$w18n;
// }