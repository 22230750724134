<template>
	<W-aos-content >
		<W-loadable :items="authResponse" skelet="spinner" />
	</W-aos-content>
</template>

<script>
export default {
	data() {
		return {
			authResponse: null
		}
	},

	async mounted() {
		const redirectTo 	= decodeURIComponent(this.$route.params.redirectToUrl)
		const token 		= new URLSearchParams(window.location.search.substring(1)).get('token') 

		try {
			await this.$store.dispatch('wAuth/login', {token: token})
			this.$router.replace(redirectTo)
		} catch (error) {
			this.authResponse = {error: error}
			this.$wToast.error(error)
		}
	}
}
</script>