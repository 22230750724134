<template>
	<div class="width-100 mt-1 mb-2">
		<ion-row class="search-wrapper ion-justify-content-between width-100" ref="searchWrapper">
			<!-- Tab name -->
			<p v-if="!difficultyFilter" class="fw-bold tab-name">{{ tabName }}</p>

			<!-- Difficulty Filter -->
			<div v-if="difficultyFilter" class="difficulty-filter mobile-hidden d-flex ion-align-items-center">
				<p class="urovne">{{$t('Level')}}:</p>
				<div class="difficulty-wrapper">
					<ion-chip class="green-outline" @click="toggleTagFilter(1)" :class="{active : availableTags.includes(1) }">
						• {{$t('Beginner')}}
						<ion-icon v-if="availableTags.includes(1)" :icon="checkmark"></ion-icon>
						<ion-icon v-else :icon="closeOutline"></ion-icon>
					</ion-chip>

					<!-- <ion-chip class="yellow-outline" @click="toggleTagFilter(2)" :class="{active : availableTags.includes(2) }">
						• {{$t('intermediate')}}
						<ion-icon v-if="availableTags.includes(2)" :icon="checkmark"></ion-icon>
						<ion-icon v-else :icon="closeOutline"></ion-icon>
					</ion-chip> -->

					<ion-chip class="orange-outline" @click="toggleTagFilter(2)" :class="{active : availableTags.includes(2) }">
						• {{$t('Advanced')}}
						<ion-icon v-if="availableTags.includes(2)" :icon="checkmark"></ion-icon>
						<ion-icon v-else :icon="closeOutline"></ion-icon>
					</ion-chip>

					<!-- <ion-chip class="red-outline" @click="toggleTagFilter(4)" :class="{active : availableTags.includes(4) }">
						• {{$t('More advanced')}}
						<ion-icon v-if="availableTags.includes(4)" :icon="checkmark"></ion-icon>
						<ion-icon v-else :icon="closeOutline"></ion-icon>
					</ion-chip> -->

					<ion-chip class="black-outline" @click="toggleTagFilter(3)" :class="{active : availableTags.includes(3) }">
						• {{$t('Expert')}}
						<ion-icon v-if="availableTags.includes(3)" :icon="checkmark"></ion-icon>
						<ion-icon v-else :icon="closeOutline"></ion-icon>	
					</ion-chip>
				</div>
			</div>

			<div v-if="showSearchAndView" class="search-and-view-wrapper d-flex ion-align-items-center" :class="{'list-active' : !grid}" ref="searchAndViewWrapper">
				<ion-searchbar @ionFocus="showSearchbar()" @ionBlur="hideSearchbar()" :placeholder="$t('Find lessons')" v-model="searchQuery" ref="searchbar"></ion-searchbar>
				<ion-icon class="search-icon size-l clickable" :icon="search" @click="openSearchbar()"></ion-icon>
				<div class="vertical-separator filter-separator" />
				<ion-icon class="filter-button clickable" :icon="funnel" @click="$emit('openModal')"></ion-icon>
				<div class="vertical-separator" />

				<p class="zobrazenie">{{$t('Display')}}:</p>
				<div class="view-icon-wrapper clickable">
					<img class="grid-icon-active" src="@/plugins/lib@profile/img/card-view-active-icon.svg" alt="">
					<img class="grid-icon" src="@/plugins/lib@profile/img/card-view-icon.svg" alt="" @click="toggleGrid(true)">
				</div>
				<div class="vertical-separator" />
				<div class="view-icon-wrapper clickable">
					<img class="list-icon-active" src="@/plugins/lib@profile/img/list-view-active-icon.svg" alt="">
					<img class="list-icon" src="@/plugins/lib@profile/img/list-view-icon.svg" alt=""  @click="toggleGrid(false)">
				</div>
				<div class="view-type-indicator"></div>
			</div>

			<div v-if="showSeparateFilter" class="search-and-view-wrapper d-flex ion-align-items-center">
				<ion-icon class="filter-button clickable" :icon="funnel" @click="$emit('openModal')"></ion-icon>
			</div>
		</ion-row>

		<div v-if="searchQuery.length > 2" class="ml-1 mt-1">
			<p class="mb-0">{{$t('Current search')}}: <strong class="ml-1">{{ searchQuery }}</strong></p>
		</div>
	</div>
</template>

<script>
import { funnel, search, checkmark, closeOutline } from 'ionicons/icons'

export default {
	props: {
		tabName: {
			type: String,
			Required: true
		},
		difficultyFilter: {
			type: Boolean,
			default: false
		},
		showSearchAndView: {
			type: Boolean,
			default: true
		},
		showSeparateFilter: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			funnel,
			search,
			checkmark,
			closeOutline,
			availableTags: [],
			grid: true,
			searchQuery: ''
		}
	},

	methods: {
		openSearchbar() {
			this.$refs.searchbar.$el.setFocus()
		},

		showSearchbar() {
			let searchWidth = 0
			if (document.documentElement.clientWidth >= 1000) {
				searchWidth = this.$refs.searchWrapper.$el.clientWidth - this.$refs.searchAndViewWrapper.clientWidth + 20
			} else if (document.documentElement.clientWidth >= 767) {
				searchWidth = this.$refs.searchWrapper.$el.clientWidth - this.$refs.searchAndViewWrapper.clientWidth + 15
			}  else {
				searchWidth = this.$refs.searchWrapper.$el.clientWidth - this.$refs.searchAndViewWrapper.clientWidth + 5
			}
			this.$refs.searchbar.$el.style.width = `${searchWidth}px`
		},

		hideSearchbar() {
			this.$refs.searchbar.$el.style.width = '0px'
		},

		toggleGrid(grid) {
			this.$emit('changeGrid', grid)
			this.grid = grid
		},

		toggleTagFilter(difficulty) {
			if (this.availableTags.includes(difficulty)) {
				this.availableTags = this.availableTags.filter(item => item != difficulty)
			} else {
				this.availableTags.push(difficulty)
			}
			this.$emit('toggledifficulty', difficulty)
		},
	},
}
</script>

<style lang="sass" scoped>
.difficulty-filter
	margin-left: 10px
	// max-width: calc( 100% - 245px )

.difficulty-wrapper
	display: flex
	flex-wrap: wrap

.tab-name 
	color: var(--ion-color-primary)
	margin-top: 13px !important
	margin-left: 10px !important
	font-size: 18px

ion-row
	border-bottom: 1px solid rgba(0, 0, 0, .1)
	padding-bottom: 10px

ion-searchbar
	width: 0
	overflow: hidden
	padding: 0
	height: 36px
	--background: #F6F6F6
	--border-radius: 3px
	transition: .3s
	z-index: 99 !important

	&::v-deep
		.searchbar-input
			font-size: 13px !important

.search-wrapper 
	height: fit-content
	min-height: 50px

	.filter-button,
	.filter-separator
		display: none

	p
		margin: 0 10px 0 0

	h1
		font-size: 38px
		line-height: 48px
		font-weight: 400
		margin: 0
	
	h4
		font-size: 18px
		line-height: 21px
		font-weight: 400
		margin: 10px 0 0

.search-and-view-wrapper
	position: relative
	align-self: flex-end
	margin-left: auto
	height: 39px

	ion-searchbar
		position: absolute
		bottom: 1px
		right: calc( 100% - 25px )

	.view-icon-wrapper
		display: flex
		justify-content: center
		align-items: center
		width: 35px

		img
			margin: 0 5px
			width: 25px

			&.grid-icon-active,
			&.list-icon
				display: block

			&.list-icon-active,
			&.grid-icon
				display: none


	.view-type-indicator
		position: absolute
		bottom: -11px
		height: 3px
		right: 52px
		width: 16px
		background: var(--ion-color-primary)
		transition: .2s

	&.list-active
		.view-type-indicator
			right: 5px

		img
			&.grid-icon-active,
			&.list-icon
				display: none !important

			&.list-icon-active,
			&.grid-icon
				display: block !important


@media only screen and (min-width: 1000px)
	.view-type-indicator
		right: 61px !important
		width: 25px !important

	.search-and-view-wrapper
		&.list-active
			.view-type-indicator
				right: 5px !important

@media only screen and (max-width: 1000px)
	// .difficulty-filter
	// 	display: block
	// 	max-width: calc( 100% - 120px )


	.zobrazenie
		display: none

	.search-wrapper
		.tab-name
			margin-left: 5px !important
			margin-top: 10px !important

		.view-icon-wrapper
			width: 26px

		img,
		ion-icon
			width: 16px !important
			height: 16px !important

		ion-chip
			font-size: 11px

	ion-label
		font-size: 14px !important

	ion-searchbar
		right: calc( 100% - 20px ) !important

@media only screen and (max-width: 850px)
	.difficulty-filter
		display: block

@media only screen and (max-width: 767px)
	.difficulty-filter
		display: none

	.search-wrapper
		padding-right: 10px

	.filter-separator
		display: block !important

	.filter-button
		display: block !important
		margin: 0 5px

	ion-searchbar
		right: calc( 100% - 10px )

@media only screen and (max-width: 575px)
	.search-wrapper
		.view-icon-wrapper
			display: none

		.view-type-indicator
			display: none

		.vertical-separator
			display: none
</style>