<template>
	<W-aos-content class="ion-no-padding">
		<ion-grid class="container">
			<ion-row>
				<ion-col size="12" class="text-center">
					<h1 class="mt-5">{{$t("Sorry, the page is temporarily unavailable.")}}</h1>
					<p class="large">{{$t("We are working to restore it.")}}</p>
					<img src="./img/404.svg" alt="">
				</ion-col>
			</ion-row>
		</ion-grid>
		<A-footer />
	</W-aos-content>
</template>

<script>
import AFooter from '@/plugins/appfumbi/_layout/footer/a-footer.vue'

export default {
	components: {
		AFooter
	},

}
</script>

<style lang="sass" scoped>
h1
	font-size: 24px
	line-height: 32px
p
	color: var(--ion-color-medium)
@media (min-width: 768px)
	h1
		padding-top: 50px
</style>