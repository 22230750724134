<template>	
	<ion-row>
		<Z-top-menu :tabName="$t('My lessons')" @openModal="$emit('openModal')" @changeGrid="toggleGrid" @searchQueryChanged="filterLessons" ref="topMenu" />

		<W-loadable :items="filteredLessons" skelet="spinner" class="lessons-wrapper width-100" :messageEmpty="lessons != '' ? $t('Search does not match any lessons!') : $t('There are no lessons in this category')">
			<ion-row class="lessons">
				<ion-col v-for="lesson in filteredLessons" :key="lesson.id" size="12" :size-sm="(!grid && !gridFixed) ? 12 : 6" :size-lg="(!grid && !gridFixed) ? 12 : 4">

					<Z-lesson-card :lesson="lesson" :grid="grid" :lessonProgress="filter == 'all'" />

				</ion-col>
			</ion-row>
		</W-loadable>
	</ion-row>
</template>

<script>
import { heart, time, search } from 'ionicons/icons'
import wAxios from '@/plugins/w/axios'
import LessonUtils from '@/plugins/lib@lesson/_utils/lesson.utils'

import ZTopMenu from './z-top-menu.vue'
import ZLessonCard from '@/plugins/lib@lesson/_components/z-lesson-card.vue'

const api = {
	getStarted: () => wAxios.get_auth_data(`v1/userflag/models/lesson/started`),
	getFinished: () => wAxios.get_auth_data(`v1/userflag/models/lesson/finished`),
	userflag: (lessonId, type, value) =>  wAxios.post_auth_data(`/v1/userflag/lesson/${lessonId}`, {type: type, value: value})
}

export default {
	props: {
		filter: {
			type: String,
			default: 'all'
		},
	},

	components: {
		ZTopMenu,
		ZLessonCard,
	},

	data() {
		return {
			heart,
			time,
			search,
			activeTab: "finished",
			lessons: [],
			filteredLessons: null,
			disableLike: false,
			searchQuery: "",
			grid: true,
			gridFixed: false,
		}
	},

	mounted() {
		this.$watch(
			'$refs.topMenu.searchQuery', (search) => {
				this.searchQuery = search
				this._filterLessons()
			}
		),

		window.addEventListener('resize', () => {
			this.gridFixed = document.documentElement.clientWidth <= 575
		})
	},

	created() {
		this._loadData()
		this.eventBus.on('language-changed', this._loadData)
	},

	beforeUnmount() {
		this.eventBus.off('language-changed', this._loadData)
	},


	methods: {
		toggleGrid(grid) {
			this.grid = grid
		},

		async _loadData() {
			if (this.$route.name != 'profile') return
			
			this.filteredLessons = null

			try {
				const finished 	= await api.getFinished()
				const started 	= await api.getStarted()
				this.lessons 	= finished.concat(started)

				this.filteredLessons = this.lessons
				this.$aos.refresh()
			} catch (error) {
				this.lessons = {error: error}
			}

			this.filterLessons(this.searchQuery)
		},

		filterLessons(searchQuery) {
			this.searchQuery 		= searchQuery
			this.filteredLessons 	= LessonUtils.filterLessons(searchQuery, this.lessons)
		},

		async toggleLike(lesson) {
			if (this.disableLike) return
			this.disableLike = true

			const value = lesson.liked_by_active_user ? 0 : 1

			try {
				await api.userflag(lesson.id, 'like', value)
				lesson.liked_by_active_user = value
			} catch (error) {
				lesson.like_by_active_user = !value
				this.$wToast.error(error)
			}

			this.disableLike = false
		},
	},

	watch: {
		async filter() {
			if (this.filter === 'finished') {
				this.lessons = await api.getFinished()
				return this._filterLessons()
			}

			if (this.filter === 'started') {
				this.lessons = await api.getStarted()
				return this._filterLessons()
			}

			const finished 	= await api.getFinished()
			const started 	= await api.getStarted()
			this.lessons 	= finished.concat(started)
			
			this._filterLessons()
		}
	},
}
</script>

<style lang="sass" scoped>
.lessons-wrapper:deep(div.ion-padding)
	padding-top: 0 !important
	padding-left: 10px

ion-card-header
	height: 200px
	background-color: #F3FAE7
	border-radius: 8px
	--border-radius: 8px
	overflow: hidden

	img
		width: 100%
		height: 100%

.is-list 
	display: flex

	ion-card-header
		width: 280px
		margin-right: 30px

	ion-chip
		position: relative

ion-card-title
	font-size: 24px
	font-weight: 700

.lesson-stats p
	font-size: 14px
	color: #666666

</style>