import { Language, Properties } from "../_types"
import { Plugins } 				from '@capacitor/core'
import { Ref, ref } 			from 'vue'
import axios 					from 'axios'
import  Translations  			from  '@/plugins/w/translate/translations'

const { Storage } = Plugins

export class W18n {
	public currentLanguage: Ref<Language> = ref();
	public availableLanguages: Language[];
	
	private languageKey: string;
	private defaultLanguage: Language | null;

	constructor(properties: Properties) {
		this.availableLanguages = properties.languages || []
		this.defaultLanguage	= properties.languages.find(language => language.value == properties.defaultLanguage)
		this.languageKey		= properties.languageKey || process.env.VUE_APP_PLATFORM + '_language' || 'w18n_language'

		this.initCurrentLanguage()
	}

	public async initCurrentLanguage() {
		let { value } = await Storage.get({ key: this.languageKey })

		if (value) {
			this.currentLanguage.value = JSON.parse(value)
		} else if (this.defaultLanguage) {
			this.currentLanguage.value = this.defaultLanguage
		} else if (this.availableLanguages[0]) {
			this.currentLanguage.value = this.availableLanguages[0]
		} else {
			return console.error('[w18n] Could not init current language')
		}

		let availableLanguages = {}

		this.availableLanguages.forEach(language => {
			availableLanguages[language.value] = language.title
		})

		Translations.setup(this.currentLanguage.value?.value, availableLanguages)
		this.changeLanguage(this.currentLanguage.value)
		this._addAxiosHeaders()
	}

	public changeLanguage(language: Language) {
		this.currentLanguage.value = language
		this._addAxiosHeaders()

		if (globalThis.Cookiebot.consented) {
			Storage.set({
				key: this.languageKey,
				value: JSON.stringify(language)
			})
		}

		Translations.setLocale(language.value)
	}

	private _addAxiosHeaders() {
		axios.interceptors.request.use(config => {
			config.headers['Accept-Language'] = this.currentLanguage?.value?.value

			return config
		})
	}
}