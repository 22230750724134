<template>
	<div class="cmf relative">
		<ion-label>{{label}}</ion-label>
		<ion-item class="ion-no-padding ion-no-margin ion-margin-vertical cmf-input-wrapper ion-text-left mt-0 mb-0" lines="none">
			<div class="start-icon" v-if="icon">
				<ion-icon :icon="icon"></ion-icon>
			</div>
			<ion-input
				v-model="fieldData"
				:disabled="readonly"
				:type="inputType"
				:placeholder="placeholder"
				@ionChange="changed($event)"/>
			<div class="toggle-password" v-if="togglePassword">
				<ion-icon @click="inputType = 'password' " v-if="inputType == 'text'" :icon="icons.eyeOutline"></ion-icon>
				<ion-icon @click="inputType = 'text' " v-else :icon="icons.eyeOffOutline"></ion-icon>
			</div>
			<!-- <div v-if="(modelValue.$dirty ) || (vBe && vBe[name])" slot="end">
				<img v-if="!hasError" src="../_assets/img/check-input.svg" alt="">
				<img v-if="hasError" src="../_assets/img/x-input.svg" alt="">
			</div> -->
		</ion-item>
		<!--FE ERRORS-->
		<div v-if="modelValue.$error && modelValue.$dirty" class="ion-no-margin error-wrapper">
			<div class="error" v-for="(error, index) in modelValue.$errors" :key="index">
				{{ i18n.global.t(validationErrors[error.$validator]) }}
			</div>
		</div>
		<!--BE ERRORS -->
		<div v-if="vBe && typeof vBe == 'object'">
			<div class="error" v-for="(error, index) in vBe[name]"  :key="index">
				{{ displayError(i18n.global.t(error)) }}
			</div>
		</div>
	</div>
</template>
<script>
import {eyeOutline, eyeOffOutline } from 'ionicons/icons'
import utils from '@/plugins/w/toast/utils'
import i18n from "@/plugins/w/translate/index"

export default {
	emits: ['update:modelValue'],
	props: {
		modelValue: Object,
		label: {
			required: false,
			default: ''
		},
		type: {
			type: String,
			required: false,
			default: 'text'
		},
		togglePassword: {
			type: Boolean,
			default: false
		},
		placeholder: {
			type: String,
			required: false,
			default: ''
		},
		readonly: {
			type: Boolean,
			required: false,
			default: false
		},
		labelPosition: {
			type: String,
			required: false,
			default: 'floating'
		},
		vBe: {
			required: false,
		},
		icon: {
			type: String,
			required: false,
		}
	},
	computed: {
		hasError() {
			if ( this.vBe && this.vBe[this.name]) {
				return true
			} else if (this.modelValue.$dirty && this.modelValue.$error) {
				return true
			}
			return false
		}
	},
	data(){
		return {
			i18n,
			inputType: '',
			fieldData: null,
			name: '',
			validationErrors: {
				required: 'Toto pole je povinné',
				email: 'Prosím zadajte platný e-mail',
				numeric: 'Toto pole musí obsahovať číslo',
				minLength: `Toto pole musí obsahovať aspoň ${this.modelValue.minLength ? this.modelValue.minLength.$params.min : ' '} znakov.`,
				sameAsPassword: 'Heslá sa nezhodujú.',
				agreed: 'Najprv musíte prijať podmienky.'
			},
			icons: {
				eyeOutline,
				eyeOffOutline
			},
		}
	},
	mounted() {
		this.name = this.modelValue.$path.split('.').pop()
		this.inputType = this.type
		this.fieldData = this.modelValue.$model
	},
	methods: {
		changed(e) {
			this.eventBus.emit('input', this.name)
			//idk if its the best solution but it does what $set() used to do
			let newValue = this.modelValue
			newValue.$model = e.target.value
			this.$emit('update:modelValue', newValue)
		},
		displayError(error) {
			return utils.parseToString(error)
		}
	},
	watch:{
		modelValue: {
			deep: true,
			handler: function(data) {
				this.fieldData = data.$model
			}
		},
	}
}
</script>
<style lang="sass" scoped>
.cmf-input-wrapper
	position: relative
	img
		position: absolute
		right: 10px
		bottom: 10px
		z-index: 20
	.toggle-password
		position: absolute
		right: 30px
		bottom: 15px
		z-index: 20
	.start-icon
		position: absolute
		left: 0px
		bottom: 13px
ion-label
	font-size: 14px
.error-wrapper
	position: absolute
	top: calc(100% + 10px)
.error
	color: red
</style>