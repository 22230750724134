<template>
	<ion-grid class="container relative py-0" data-aos="fade-up">
		<ion-row class="wrapper py-3">
			<ion-col size="12" size-md="6" class="left py-3">
				<h2 class="mb-2">{{$t('Get information')}} <span class="color-primary">{{$t('among the first')}}</span></h2>
				<p class="hidden-sm">{{$t('newsletter-text')}}</p>
			</ion-col>
			
			<ion-col size="12" size-md="6" class="input-outer d-flex flex-direction-column ion-justify-content-center py-3 px-4">
				<p class="color-primary">{{$t('Newsletter subscription')}}</p>

				<div class="input-wrapper d-flex ion-align-items-center">
					<cmf-input v-model="v$.email" :vBe="vBe" :placeholder="$t('You can write your email here')" />
					<img class="icon" src="./img/letter.svg" alt="letter" @click="sendMail">
				</div>

				<div class="d-flex ion-align-items-center mt-2">
					<ion-checkbox v-model="checked" class="mr-1" color="primary" slot="start"></ion-checkbox>
					<ion-label>{{$t('I agree with')}} <a :href="`https://fumbi.network/${$w18n.currentLanguage.value.value}/zasady-ochrany-osobnych-udajov`" target="_blank" >{{$t('data processing')}}</a></ion-label>
				</div>
			</ion-col>
		</ion-row>
	</ion-grid>
</template>

<script>
import wAxios from '@/plugins/w/axios'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

const api = {
	newsletter: (data) => wAxios.post(`${process.env.VUE_APP_FUMBI_API}/v1/newsletter`, {email: data})
}

export default {
	data() {
		return {
			checked: false,
			email: null,
			v$: useVuelidate(),
			vBe: [],
		}
	},

	validations() {
		return {
			email: { required, email }
		}
	},

	methods: {
		async sendMail() {
			if (!(await this.v$.$validate()) ) {
				return
			}

			if (this.checked === false) return this.$wToast.error(this.$t('You have to accept terms first!'))

			try {
				await api.newsletter(this.email)
				this.$wToast.success(this.$t('You have been subscribed!'))
				this.email 		= null
				this.checked 	= false
				setTimeout(() => { this.v$.$reset() }, 10)
			} catch (error) {
				this.$wToast.error(this.$t('Failed to subscribe!'))
			}
		}
	},
}
</script>

<style lang="sass" scoped>
.container
	margin-top: 150px
	&:before
		content: ""
		display: block
		width: 1000px
		height: 100%
		background-color: #EEF7E2
		position: absolute
		right: calc(100% - 15px)
		top: 0
.wrapper
	background-color: #EEF7E2
	.left
		@media (min-width:768px)
			border-right: 1px solid #C5DEA5
		@media (min-width:1200px)
			padding-right: 150px
.input-wrapper
	background-color: #fff
	border: 1px solid #c5dea5
	border-radius: 7px
	.icon
		padding: 17px
		width: 61px
		height: 61px
:deep(.cmf)
	flex: 1 1 auto 
	border-right: 1px solid #C5DEA5
ion-checkbox
	--border-radius: 4px
	border-radius: 4px
	--size: 20px
@media (max-width: 1023px)
	.container 
		margin-top: 120px
	.input-outer
		padding-left: 15px
		padding-right: 15px
@media (max-width: 767px)
	.hidden-sm
		display: none
	.container 
		margin-top: 80px
		&:after
			content: ""
			display: block
			width: 1000px
			height: 100%
			background-color: #EEF7E2
			position: absolute
			left: calc(100% - 15px)
			top: 0
	.input-outer
		padding-top: 0
		padding-left: 0
		padding-right: 0
</style>