<template>
	<ion-page>
		<ion-content class="ion-no-padding">
			<ion-icon slot="end" class="size-l" :icon="closeOutline" @click="closeModal()"></ion-icon>
			<iframe width="100%" height="100%" src="https://www.youtube.com/embed/KCNt_KoHLm8" title="YouTube video player" 
				frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen">
			</iframe>
		</ion-content>
	</ion-page>
</template>

<script>
import { modalController } 	from '@ionic/vue'
import { closeOutline} 		from 'ionicons/icons'

export default {
	data() {
		return {
			closeOutline
		}
	},

	methods: {
		closeModal() {
			modalController.dismiss()
		}
	},
}
</script>