<template>
	<div>
		<A-DesktopHeader class="hidden-mobile" />
		<A-MobileHeader class="hidden-desktop" />
	</div>
</template>

<script>
import ADesktopHeader 				from './_components/a-desktop-header.vue'
import AMobileHeader 				from './_components/a-mobile-header.vue'

export default {
	components: {
		ADesktopHeader,
		AMobileHeader
	},
}
</script>


<style lang="sass" scoped>
.hidden-mobile
	display: block
.hidden-desktop
	display: none

@media (max-width: 992px)
	.hidden-mobile
		display: none
	.hidden-desktop
		display: block
</style>